import React, { Component } from "react";
import { Link } from "react-router-dom";

import { URL_PORTAL_OIADS } from "../../../constants";

import LOGO_OIADS from "./images/logo-oi-hub.png";

export default class OiAds extends Component {
  /**
   * Get Products Oi Ads
   */
  getProducts = (data) => {
    return data.map((rows, key) => {
      return (
        <div
          className="flex column aic jcc"
          key={key}
          style={{ backgroundColor: "transparent", padding: 0 }}
        >
          <div className="flex column">
            <img
              src={LOGO_OIADS}
              style={{ width: 30 }}
              className="logo-dashboard"
              alt=""
            />
            <strong style={{ marginBottom: -10 }}>Ads</strong>
          </div>
          <a
            target="_blank"
            id="acessar-meu-erp"
            style={{ width: "100%", backgroundColor: "#00AB0F" }}
            rel="noopener noreferrer"
            href={URL_PORTAL_OIADS + `?t=${this.props.idToken}`}
            className="bt-plan plan_mkt_digital_ads"
          >
            ACESSAR MEU OI ADS
          </a>
          <Link
            className="help_mtk_digital_ads"
            id="bt-ajuda-erp"
            style={{
              textDecoration: "none",
              marginTop: 10,
              color: "hsla(0, 0%, 40%, 1)",
              fontSize: 14,
            }}
            to="/ajuda-oi-ads"
          >
            Ajuda
          </Link>
        </div>
      );
    });
  };

  /**
   * Render Component
   */
  render() {
    return (
      <section
        className="plan-box flex column color-plan jcb"
        style={{ borderTop: "4px solid #00AB0F" }}
      >
        <div className="flex column">
          <div className="top-header flex aic jcb">
            <small>Suas Vendas</small>
            {/* <Link id="bt-ajuda-erp" to="/ajuda-oi-ads">Ajuda</Link> */}
          </div>
          <header className="flex aic jcc">
            <div className="flex column">
              <small>Plano</small>
              <h1>Marketing Digital</h1>
            </div>
          </header>
        </div>
        <section className="itens-plan flex flex-wrap">
          {this.getProducts(this.props.rows)}
        </section>
      </section>
    );
  }
}
