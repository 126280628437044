import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import DownloadDesktop from "../../Buttons/DownloadDesktop";
import DownloadAppleStore from "../../Buttons/DownloadAppleStore";
import DownloadGooglePlay from "../../Buttons/DownloadGooglePlay";
import DownloadCloud from "../../Buttons/DownloadCloud";

import {
  URL_PORTAL_CLOUD,
  URL_DOWNLOAD_APP_HERO_IOS,
  URL_DOWNLOAD_APP_HERO_ANDROID,
  URL_DOWNLOAD_APP_OI_SEGURANCA_IOS,
  URL_DOWNLOAD_APP_OI_SEGURANCA_ANDROID,
} from "../../../constants";

import LOGO_OI_HUB from "./images/logo-oi-hub.png";

const ProductsSegurancaLight = ({ user_id, rows }) => {
  const listProducts = (type, data) => {
    if (type === "Segurança Completa") {
      return (
        <div className="flex column aic jcc" key={data.id}>
          <span>
            <strong>
              {data.licenses_size} {data.licenses_unit}
            </strong>
          </span>
          <small>{data.name}</small>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 15,
              marginTop: 15,
            }}
          >
            <DownloadAppleStore url={URL_DOWNLOAD_APP_HERO_IOS} />
            <DownloadGooglePlay url={URL_DOWNLOAD_APP_HERO_ANDROID} />
            {!isMobile && <DownloadDesktop />}
          </div>
        </div>
      );
    }

    if (type === "Proteção Sync") {
      return (
        <div className="flex column aic jcc" key={data.id}>
          <span>
            <strong>
              {data.licenses_size} {data.licenses_unit}
            </strong>
          </span>
          <small>{data.name}</small>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 15,
              marginTop: 15,
            }}
          >
            {!isMobile ? (
              <DownloadCloud url={URL_PORTAL_CLOUD} />
            ) : (
              <>
                <DownloadAppleStore url={URL_DOWNLOAD_APP_OI_SEGURANCA_IOS} />
                <DownloadGooglePlay
                  url={URL_DOWNLOAD_APP_OI_SEGURANCA_ANDROID}
                />
              </>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <section className="plan-box flex column">
      <div className="top-header flex aic jcb">
        <small>Sua Segurança</small>
        <Link
          className="help_oi_seguranca"
          id="bt-ajuda-oi-light"
          to="/ajuda-oi-seguranca"
        >
          Ajuda
        </Link>
      </div>
      <header className="flex aic jcs">
        <img
          src={LOGO_OI_HUB}
          style={{ marginRight: "1rem", width: "50px" }}
          className="logo-dashboard"
          alt=""
        />
        <div className="flex column">
          <small>Plano</small>
          <h1>Oi Segurança Light</h1>
        </div>
      </header>
      <section className="itens-plan flex flex-wrap">
        {rows.map((row) => listProducts(row.name, row))}
      </section>
    </section>
  );
};

ProductsSegurancaLight.propTypes = {
  user_id: PropTypes.number.isRequired,
  rows: PropTypes.array,
};

export default ProductsSegurancaLight;
