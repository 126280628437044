import React from 'react'
import { URL_CHAT } from '../../../constants'

export default () => {
    return (
        <div className="center">
            <section className="grid-box flex column">
                <a href="/area-logada" style={{ color: 'rgb(76, 184, 72)', fontSize: '24px', textDecoration: 'underline' }}>Voltar para Home</a>
            </section>
            <section className="grid-box flex column">
                <header className="flex column">
                    <h1 style={{ fontSize: '2.4rem' }}>Meus Planos</h1>
                </header>
                <section className="list-box">
                    <h1>Ops, ocorreu um erro ao exibir seus produtos! Clique <a target="_blank" rel="noopener noreferrer" href={URL_CHAT}>aqui</a> para falar conosco!</h1>
                </section>
            </section>
        </div>
    );
};