import React from 'react'
import ModalLogin from '../../../Modal/Login/Home/EGestor'

export default () => {
    return (
        <div>

            <div className="frm-login-acelerai">
                <ModalLogin />
                <a href="https://api.whatsapp.com/send?phone=553180013131&text=Ol%C3%A1!%20Quero%20conhecer%20as%20ofertas%20da%20Oi" rel="noopener noreferrer" className="second-button" target="_blank">Ainda não sou cliente. Quero aderir.</a>
            </div>

        </div>
    );
};