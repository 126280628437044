import React from 'react'
import PropTypes from 'prop-types';

const FormSendEmail = ({ display, handleSubmit, email, handleChange }) => {
    return (
        <div className={display}>
            <header className="flex column header-form">
                <h1><strong>Valide seu email</strong></h1>
                <h2>Agora, informe um e-mail para validarmos sua conta:</h2>
            </header>
            <form className="frm-asso flex column" onSubmit={handleSubmit}>
                <div className="flex aic">
                    <label htmlFor="email" className="aic jcc">
                        <i className="fas fa-envelope" />
                    </label>
                    <input
                        value={email}
                        onChange={handleChange}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Digite seu e-mail"
                        required />
                </div>
                <button className="btn-login register_email">Enviar</button>
            </form>
        </div>
    );
};

FormSendEmail.propTypes = {
    email: PropTypes.string.isRequired,
};

export default FormSendEmail;