import React from "react";
import ModalLogin from "../../../../components/Modal/Login";
import ModalMergeAccount from "../../../../components/Modal/MergeAccount";
import FooterHome from "../../../../components/Footer/Home";

import BoxLoginOList from "../../../../components/Products/Home/EGestor";

import LOGOOIHUB from "../../images/logo-oi-hub.png";

import BannerHomeTop from "../../../../components/Banner/HomeTop";

import FaqEGestor from "../../HomeFaq/EGestor";

export default () => {
  return (
    <div>
      {/* MENU MOBILE */}
      <div className="content-menu-mobile">
        <div className="mask"></div>
        <div className="menu-mobile">
          <div>
            <div className="login-section">
              <div className="_conte">
                <p>
                  <small>Já está cadastrado? Efetue o Login:</small>
                </p>
                <ModalLogin />
                <ModalMergeAccount />
              </div>

              <div className="js-close close-menu-mobile">
                <i className="fas fa-times"></i>
              </div>
            </div>

            <div className="content-list-items">
              <div className="back-page-product">
                <a href="/">
                  <i className="fas fa-chevron-left"></i>
                  <p>Home</p>
                </a>
              </div>

              <ul className="list-items">
                <li>
                  <p>
                    <a
                      href="/"
                      className="js-close color-transition nossos_servicos-oi_seguranca"
                    >
                      Oi Segurança
                    </a>
                  </p>
                </li>

                <li>
                  <p>
                    <a
                      href="/"
                      className="js-close color-transition ativa nossos_servicos-egestor"
                    >
                      Gestão Empresarial
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <main className="oi_home">
        {/* HEADER */}
        <header className="_header">
          <div className="content-center">
            {/* LOGO */}
            <div className="logo">
              <a href="/">
                <img src={LOGOOIHUB} alt="Logo Oi Hub" />
              </a>
            </div>

            {/* PRODUCT */}
            <div className="product">
              <button className="show-product nossos_servicos">
                Nossos Serviços
              </button>

              <a href="#category" className="quero_ser_cliente_oi">
                Quero ser Cliente Oi
              </a>

              <a href="#_footer" className="preciso_de_ajuda">
                Preciso de Ajuda
              </a>

              <a
                href="https://www.oicontasb2b.com.br/#/oi/login"
                target="_blank"
                rel="noopener noreferrer"
                className="segunda_via_conta"
              >
                2ª via de Conta
              </a>
            </div>

            {/* LOGIN */}
            <div className="login-section">
              <p>
                <small>Já está cadastrado? Efetue o Login:</small>
              </p>
              <ModalLogin />
              <ModalMergeAccount />
            </div>

            {/* MENU HAMBUGUER */}
            <div className="menu-hamburguer">
              <button className="menu-mobile">
                <i className="fas fa-bars"></i>
              </button>
            </div>
          </div>

          {/* ITEMS MENU */}
          <div className="items-menu height-transition">
            <div className="content-center">
              <ul className="list-items">
                <li>
                  <p>
                    <a
                      href="/"
                      className="color-transition nossos_servicos-olist"
                    >
                      Olist
                    </a>
                  </p>
                </li>

                <li>
                  <p>
                    <a
                      href="/"
                      className="color-transition ativa nossos_servicos-egestor"
                    >
                      Gestão Empresarial
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <a
                      href="/"
                      className="color-transition nossos_servicos-oi_seguranca"
                    >
                      Oi Segurança
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </header>

        {/* BANNER */}
        <BannerHomeTop />

        {/* CONTENT */}
        <section className="_content">
          {/* Encompass */}

          {/* OLIST */}
          <div
            className="emcompass-product"
            id="box-plan"
            data-emcompass="olist"
          >
            {/* INFORMATION PLAN */}
            <div className="info-plan">
              <div className="content-center">
                <section className="_box">
                  <div className="login-plan">
                    <BoxLoginOList />
                  </div>
                </section>
              </div>
            </div>

            {/* FAQ */}
            <div className="content-faq">
              <FaqEGestor />
            </div>
          </div>
        </section>

        {/* FOOTER */}
        <FooterHome />
      </main>
    </div>
  );
};
