/** FIREBASE ENVIRONMENT */
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL =
  process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SEND_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SEND_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

/** APPLICATION ENVIRONMENT */
export const NODE_ENV = process.env.NODE_ENV;
export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME;
export const REQUESTER_NAME = process.env.REACT_APP_REQUESTER_NAME;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const URL_BACKEND = process.env.REACT_APP_URL_BACKEND;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const URL_FIREBASE = process.env.REACT_APP_URL_FIREBASE;
export const API_KEY_FIREBASE = process.env.REACT_APP_API_KEY_FIREBASE;
export const URL_CHAT = process.env.REACT_APP_URL_CHAT;
export const URL_CHAT_OI = process.env.REACT_APP_URL_CHAT_OI;
export const URL_CHAT_EGESTOR = process.env.REACT_APP_URL_CHAT_EGESTOR;
export const URL_DOWNLOAD_MANAGER = process.env.REACT_APP_URL_DOWNLOAD_MANAGER;
export const URL_PORTAL_EGESTOR = process.env.REACT_APP_URL_PORTAL_EGESTOR;
export const URL_PORTAL_OIADS = process.env.REACT_APP_URL_PORTAL_OIADS;
export const URL_CHAT_WHATSAPP = process.env.REACT_APP_URL_CHAT_WHATSAPP;
export const URL_PORTAL_POSTGRAIN = process.env.REACT_APP_URL_PORTAL_POSTGRAIN;
export const URL_CHAT_WHATSAPP_OI = process.env.REACT_APP_URL_CHAT_WHATSAPP_OI;
export const URL_PORTAL_CLOUD = process.env.REACT_APP_URL_PORTAL_CLOUD;

export const URL_DOWNLOAD_APP_HERO_IOS =
  process.env.REACT_APP_URL_DOWNLOAD_APP_HERO_IOS;
export const URL_DOWNLOAD_APP_HERO_ANDROID =
  process.env.REACT_APP_URL_DOWNLOAD_APP_HERO_ANDROID;

export const URL_DOWNLOAD_APP_OI_SEGURANCA_IOS =
  process.env.REACT_APP_URL_DOWNLOAD_APP_OI_SEGURANCA_IOS;
export const URL_DOWNLOAD_APP_OI_SEGURANCA_ANDROID =
  process.env.REACT_APP_URL_DOWNLOAD_APP_OI_SEGURANCA_ANDROID;
