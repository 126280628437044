import React, { Component } from 'react'
import { validatorPhoneNumber } from '../../../../../utils/Validator/PhoneNumber'
import { createToken } from '../../../../../utils/Token'
import {
    sendPincode,
    validatePincode,
    authorization,
    verifyCustomToken,
    getPacks
} from '../../../../../services'

import {
    ErrorMessage,
    Loading
} from '../../../../../components'

import FormSendPincodeOiSeguranca from '../../../../../components/Forms/Modal/Login/SendPincode/Home/OiSeguranca'
import FormValidatePincodeOiSeguranca from '../../../../../components/Forms/Modal/Login/ValidatePincode/Home/OiSeguranca'

import { URL_DOWNLOAD_MANAGER } from '../../../../../constants'

export default class ModalLogin extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            loading: false,
            errorMessage: '',
            displayErrorMessage: 'none',
            displayModal: 'hidden',
            displayFormSendPincodeOiSeguranca: 'show',
            displayFormValidatePincodeOiSeguranca: 'hidden',
            phone: '',
            transaction_id: '',
            pincode: '',
            fs_custom_token: '',
            custom_token: '',
            redirect: false
        }

        this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this); 
        this.handleChangeFormSendPincodeOiSeguranca = this.handleChangeFormSendPincodeOiSeguranca.bind(this);
        this.handleSubmitFormSendPincodeOiSeguranca = this.handleSubmitFormSendPincodeOiSeguranca.bind(this);
        this.handleChangeFormValidatePincodeOiSeguranca = this.handleChangeFormValidatePincodeOiSeguranca.bind(this);
        this.handleSubmitFormValidatePincodeOiSeguranca = this.handleSubmitFormValidatePincodeOiSeguranca.bind(this);
    }

    /**
     * On Click Modal Display
     */
    onClickModalDisplay = (v) => {
        switch (v) {
            case true:
                this.setState({
                    displayModal: 'show',
                });
                break;

            default:
                let err = document.getElementsByClassName('div-error');

                for (var i = 0; i < err.length; i++) {
                    err[i].style.display = 'none';
                }

                this.setState({
                    displayModal: 'hidden',
                    displayFormSendPincodeOiSeguranca: 'show',
                    displayFormValidatePincodeOiSeguranca: 'hidden'
                });
                break;
        }
    }

    /**
     * On Close Error Message
     */
    onCloseErrorMessage = (e) => {
        this.setState({ displayErrorMessage: e, errorMessage: '' });
    }

    /**
     * Handle Change Form Send Pincode
     * @param {event} e 
     */
    handleChangeFormSendPincodeOiSeguranca(e) {
        this.setState({ phone: e.target.value });
    }

    /**
     * Handle Submit Form Send Pincode
     * @param {event} e 
     */
    async handleSubmitFormSendPincodeOiSeguranca(e) {
        e.preventDefault();

        const { phone } = this.state;
        const validator = validatorPhoneNumber(phone);
        
        this.setState({ loading: true, displayErrorMessage: 'none', errorMessage: '' });

        if(!validator.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: validator.message });
            return;
        }

        const response = await sendPincode({ 
            msisdn: validator.phone 
        });
        
        if(!response.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response.message });
            return;
        }

        this.setState({
            loading: false,
            phone: validator.phone,
            displayFormSendPincodeOiSeguranca: 'hidden',
            displayFormValidatePincodeOiSeguranca: 'show',
            transaction_id: response.transaction_id
        });        
    }

    /**
     * Handle Change Form Validate Pincode
     * @param {event} e 
     */
    handleChangeFormValidatePincodeOiSeguranca(e) {
        this.setState({ pincode: e.target.value });
    }

    /**
     * Handle Submit Form Validate Pincode
     * @param {event} e 
     */
    async handleSubmitFormValidatePincodeOiSeguranca(e) {
        e.preventDefault();
        const { transaction_id, pincode, phone } = this.state;

        this.setState({ loading: true, displayErrorMessage: 'none', errorMessage: '' });

        const response1 = await validatePincode({ 
            transaction_id: transaction_id, 
            pincode: pincode
        });

        if(!response1.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response1.message });
            return;
        }
        
        const response2 = await authorization({ 
            'fs-auth-token': response1.fs_auth_token 
        });

        if(!response2.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response2.message });
            return;
        }

        const response3 = await verifyCustomToken({ 
            token: response2.custom_token, 
            returnSecureToken: true 
        });

        if(!response3.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response3.message });
            return;
        }

        await createToken(response3.id_token, response2.custom_token, phone);

        let responsePacks = await getPacks();
        if (responsePacks.status){
            let redirectUrl = `${URL_DOWNLOAD_MANAGER}?produto=pom&serial=${responsePacks.user.id}`
            setTimeout(async () => {
                this.setState({
                    displayModal: 'hidden',
                    displaySuccessMergeAccount: 'hidden',
                    redirect: true,
                    redirectUrl: redirectUrl
                }); 
            }, 100);
        }else{
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: responsePacks.message });
        }
        
    }

    /**
     * Render Component
     */
    render() {
        const {
            loading,
            errorMessage,
            displayErrorMessage,
            displayFormSendPincodeOiSeguranca,
            displayFormValidatePincodeOiSeguranca,
            phone,
            transaction_id,
            redirect,
            redirectUrl,
            pincode
        } = this.state;        

        if (redirect) {
            window.location.href = redirectUrl;
        }

        return (
            <>
                <Loading display={loading} />
                <div>

                    <FormSendPincodeOiSeguranca
                        phone={phone}
                        analytics={"insert_msisdn"}
                        handleChange={this.handleChangeFormSendPincodeOiSeguranca}
                        handleSubmit={this.handleSubmitFormSendPincodeOiSeguranca}
                        display={displayFormSendPincodeOiSeguranca}
                    />

                    <FormValidatePincodeOiSeguranca
                        analytics={"validate_msisdn"}
                        resendPin={"resend_pincode_msisdn"}
                        phone={phone}
                        pincode={pincode}
                        transaction_id={transaction_id}
                        handleResendPincode={this.handleResendPincode}
                        handleChange={this.handleChangeFormValidatePincodeOiSeguranca}
                        handleSubmit={this.handleSubmitFormValidatePincodeOiSeguranca}
                        display={displayFormValidatePincodeOiSeguranca}
                    />
                    <ErrorMessage display={displayErrorMessage} onCloseErrorMessage={(e) => this.onCloseErrorMessage(e)} message={errorMessage} />
                </div>
            </>
        )
    }
}