import React from "react";
import LOGOBANNEREGESTOR from "./images/logo-egestor.svg";
import LOGOBANNEROISEGURANCA from "./images/logo-oi-seguranca-branco.png";
import "./banner.css";

export default () => {
  return (
    <section className="_banner">
      <ul className="list-banner owl-carousel owl-theme">
        <li className="item-banner-oiseguranca">
          <div className="content-center">
            <img src={LOGOBANNEROISEGURANCA} alt="Logo Banner" />

            <p className="text-banner">Sua vida digital protegida</p>
          </div>
        </li>
        <li className="item-banner-egestor">
          <div className="content-center">
            <img src={LOGOBANNEREGESTOR} alt="Logo Banner E-Gestor" />

            <p className="text-banner">
              O controle da sua empresa em um único lugar
            </p>
          </div>
        </li>
      </ul>
    </section>
  );
};
