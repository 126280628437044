import React, { Component } from 'react'
import Collapsible from 'react-collapsible'
import Layout from '../../../layout'
import Help from './help.json'
import './styles.css'

export default class AjudaEGestor extends Component {

    componentDidMount() {
        document.querySelector('chatbot').style.display = 'block';
        setTimeout(()=>dispatchEvent(new Event("chatbotEgestor")), 50);
    }

    componentWillUnmount() {
        document.querySelector('chatbot').style.display = 'none';
    }

    render() {
        return (
            <React.Fragment>
                <Layout displayFooter="hidden">
                    <div className="center">
                        <section className="grid-box flex column">
                            <a href="/area-logada" style={{ color: 'rgb(76, 184, 72)', fontSize: '24px', textDecoration: 'underline' }}>Voltar para Home</a>
                        </section>
                        <section className="grid-box flex column">
                            <header className="flex column" style={{ marginBottom: '1rem' }}>
                                <h1 style={{ fontSize: '2.4rem' }}>BEM-VINDO À ÁREA DO CLIENTE</h1>
                                <h1 style={{ marginTop: '3rem' }}>SOBRE O EGESTOR</h1>
                            </header>
                            <section className="">
                                {Help.map((obj, key) => {
                                    return <Collapsible className={`help_egestor_question_${key + 1}`} trigger={obj.question.toUpperCase()} key={key}>
                                        <p style={{ whiteSpace: 'pre-line' }}>{obj.answer}</p>
                                    </Collapsible>;
                                })}
                            </section>
                        </section>
                    </div>
                    <section className="modulo-contato flex">
                        <div className="center-content flex column">
                            <header className="flex column">
                                <h1>Não encontrou o que precisa?</h1>
                                <h2><a className="help_egestor_youtube" href="https://www.youtube.com/results?search_query=egestor" target="_blank" rel="noopener noreferrer">Acesse nosso canal no Youtube</a></h2>
                            </header>
                            <div className="flex flex-wrap">
                                <div className="box-contato flex column">
                                    <h2>Escreva para:</h2>
                                    <a href="mailto:atendimento@zipline.com.br">atendimento@zipline.com.br</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            </React.Fragment>
        )
    }
}