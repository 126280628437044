import React from 'react'
import ButtonLogout from '../Buttons/Logout'
import LOGO_IMAGE from './images/logo-oi-hub.svg'

export default () => {
    return (
        <nav className="flex aic jcb">
            <div className="center flex aic jcb">
                <p className="md" />
                <img src={LOGO_IMAGE} id="logo" className="logo-dashboard" alt="" />
                <div className="flex column aie box-inf">                  
                    <ButtonLogout />
                </div>
            </div>
        </nav>
    );
}