import styled from "styled-components";

export const Button = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  border-radius: 0.6rem;
  font-size: 13px;
  line-height: 3.2em;
  padding: 5px 20px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
`;
