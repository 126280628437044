import React from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types';

function cnpjMask(v){
    return v.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/, "$1.$2.$3/$4-$5")
}

const FormValidateCNPJ = ({ display, handleSubmit, cnpj, handleChange}) => {
    return (
        <div className={display}>
            <header className="flex column header-form">
                <h1><strong>Cadastre seus dados</strong></h1>
                <h2>Para começar, precisamos que você nos informe seu CNPJ:</h2>
            </header>            
            <form className="frm-asso flex column" onSubmit={handleSubmit}>
                <div className="flex aic">
                    <label htmlFor="cnpj" className="aic jcc">
                        <i className="fas fa-address-card" />
                    </label>
                    <NumberFormat
                        value={cnpj}
                        onChange={handleChange}
                        type="cnpj"
                        name="cnpj"
                        placeholder="Digite seu CNPJ"
                        required
                        format={cnpjMask}
                        autoFocus={true}
                        maxLength={18}
                    />
                </div>
                <button className="btn-login insert_cnpj">Consultar</button>            
            </form>
        </div>
    );
};

FormValidateCNPJ.propTypes = {
    display: PropTypes.string.isRequired,
    cnpj: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
  };
  
  export default FormValidateCNPJ;