import React, { useEffect, useState } from "react";
import { osName } from "react-device-detect";
import ICON_DESKTOP from "../../../images/icon-windows.png";
import { getDownloadLinkSecurity } from "../../../services/BFF";
import { Button } from "./styled";

const DownloadDesktop = () => {
  const [url, setUrl] = useState("");
  const [loader, setLoader] = useState(false);

  const listData = async () => {
    const getDownloadLinkSecurityResponse = await getDownloadLinkSecurity({
      platform: osName === "Mac OS" ? "macOS" : "Windows",
    });

    if (!getDownloadLinkSecurityResponse.status) {
      setLoader(false);
      return;
    }

    setUrl(getDownloadLinkSecurityResponse.downloadLink);
    setLoader(true);
  };

  useEffect(() => {
    listData();
  }, []);

  if (!loader) {
    return null;
  }

  return (
    <Button href={url} target="_blank" rel="noopener noreferrer">
      <img src={ICON_DESKTOP} alt="" width={40} /> Baixe agora
    </Button>
  );
};

export default DownloadDesktop;
