import React from "react";
import { URL_PORTAL_CLOUD } from "../../../constants";
import { Button } from "./styled";

const ButtonCloud = () => {
  return (
    <Button href={URL_PORTAL_CLOUD} target="_blank" rel="noopener noreferrer">
      Acesse o portal
    </Button>
  );
};

export default ButtonCloud;
