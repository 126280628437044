import React from 'react'
import ButtonResendPincode from '../../../../Buttons/ResendPincode'
import PropTypes from 'prop-types';
import './styles.css'

const FormValidatePincodeEmail = ({ display, email, handleSubmit, pincode, handleChange, resendPin }) => {
    return (
        <div className={display}>
            <header className="flex column header-form">
                <h1><strong>Validando seu email...</strong></h1>
                <h2>Foi enviado um código de confirmação para o email {email}. Peço que digite o mesmo no campo abaixo:</h2>
            </header>
            <form className="frm-asso flex column" onSubmit={handleSubmit}>
                <div className="flex aic">
                    <label htmlFor="cel" className="aic jcc">
                        <i className="fas fa-key" />
                    </label>
                    <input
                        value={pincode}
                        onChange={handleChange}
                        type="pincode"
                        name="pincode"
                        placeholder="Digite o código recebido"
                        maxLength={6}
                        required />
                </div>
                <ButtonResendPincode resendPin={resendPin} type={'email'} value={email} />
                <button className="btn-login validate_register_email">Validar</button>
            </form>
        </div>
    );
};

FormValidatePincodeEmail.default = {
    resendPin: ''
}

FormValidatePincodeEmail.propTypes = {
    display: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    pincode: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    resendPin: PropTypes.string
  };
  
  export default FormValidatePincodeEmail;