/**
 * Function Validator CNPJ
 * @param {int} cnpj
 */
export function maskCNPJ(value) {

    if(!value){
        return null;
    }

    value=value.replace(/\D/g,"");
    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/, "$1.$2.$3/$4-$5")
}