import React from 'react'
import PropTypes from 'prop-types';

const ModalTemplate = ({ display, id, onClickModalDisplay, children }) => {
    return (
        <div className={`modal flex aic jcc ${display}`}>
            <section className={`box-modal flex column ${display}`} id={id}>
                <div className="close flex aic jce">
                    <i className="fas fa-times" onClick={() => onClickModalDisplay(false)} />
                </div>
                {children}
            </section>
        </div>
    );
};

ModalTemplate.defaultProps = {
    id: ''
}

ModalTemplate.propTypes = {
    children: PropTypes.node.isRequired,
    display: PropTypes.string.isRequired,
    id: PropTypes.string,
    onClickModalDisplay: PropTypes.func.isRequired,
  };
  
  export default ModalTemplate;