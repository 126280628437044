import axios from 'axios'
import * as env from '../../constants'

/**
 * Constants
 */
const URL = env.URL_FIREBASE;
const API_KEY = env.API_KEY_FIREBASE;

const HEADERS = {
    'Content-Type': 'application/json'
}

/**
 * Function Verify Custom Token
 * @param {json} body 
 */
export async function verifyCustomToken(body){
    try {
        console.log('[send-custom-token]:', body);
        let response = await axios.post(`${URL}/verifyCustomToken?key=${API_KEY}`, body, { headers: HEADERS });
        console.log('[success-id-token]:', response.data.idToken);
        return { status: true, id_token: response.data.idToken };
    } catch (error) {
        if (error.response) {
            console.log('[error-business]: ', error.response.data);
            return { status: false, message: 'Ops, ocorreu um erro. Tente novamente' };
        } else if (error.request) {
            console.log('[error-request]:', error.request)
            return { status: false, message: 'Ops, ocorreu um erro. Tente novamente!' };
        } else {
            console.log('[error-api]:', error.request)
            return { status: false, message: 'Ops, ocorreu um erro. Tente novamente!' };
        }
    }
}

/**
 * Function Verify Id Token
 * @param {json} body 
 */
export function getAccountInfo(body){
    return axios.post(`${URL}/getAccountInfo?key=${API_KEY}`, body, { headers: HEADERS });
}