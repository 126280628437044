import React from 'react'
// import ModalAjudaLogin from '../../Modal/AjudaLogin'

import LOGOOIHUB from '../../../pages/Home/images/logo-oi-hub.svg'

export default () => {
    return (
        <div>
            <footer className="_footer" id="_footer">

            <div className="content-center">
                {/* COPYRIGHT */}
                <div className="_copyright">
                    <ul>
                        <li>
                            <a href="https://dpo.privacytools.com.br/policy-view/YA0qJp5xA/3/termos-de-uso/pt_BR?s=1614784418941" rel="noopener noreferrer" target="_blank" className="termo_de_privacidade">Termos de uso e Privacidade</a>
                        </li>

                        <li>
                            <a href="https://portal.privacytools.com.br/portal/59c9a4d4-e564-43ff-80ed-3ad86420ab50" rel="noopener noreferrer" target="_blank" className="portal_de_privacidade">Portal de privacidade</a>
                        </li>
                    </ul>

                    <p>Ao acessar o Oi Seu Negócio, você está ciente de que alguns dados de sua empresa serão compartilhados com os nossos parceiros de negócios. As informações compartilhadas são: razão social, CNPJ, número do telefone, e-mail, cidade e estado. Isso garante o acesso correto aos serviços disponíveis.</p>
                </div>
            </div>

            {/* SOCIAL BAR */}
            <div className="_socialbar">
                <div className="content-center">
                    <ul className="list-socialbar">
                        <li>
                            <a href="https://api.whatsapp.com/send?phone=553180013131&text=Ol%C3%A1!%20Quero%20conhecer%20as%20ofertas%20da%20Oi" rel="noopener noreferrer" target="_blank" className="vendas_whatsapp">
                                <div>
                                    <i className="fab fa-whatsapp"></i>
                                </div>

                                <p>Central de Vendas WhatsApp</p>
                            </a>
                        </li>

                        <li>
                            <a href="https://www.oi.com.br/oiseunegocio" rel="noopener noreferrer" target="_blank" className="conheca_oi_fibra">
                                <div>
                                    <img src={ LOGOOIHUB } alt="Logo Oi Hub" />
                                </div>

                                <p>Conheça a Oi Fibra</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            </footer> 
        </div>
    );
}