import React from 'react'
import reactStringReplace from 'react-string-replace'
import PropTypes from 'prop-types';

const Errors = ({ onCloseErrorMessage, display, message }) => {
    return (
        <div className="div-error msisdn_contact_us" style={{ display: `${display}` }}>
            <div className="close-error msisdn_contact_us" onClick={(e) => onCloseErrorMessage('none')} id="close-error">
                <i className="fas fa-times"></i>
            </div>
            <p>
                {reactStringReplace(message, /(http?:\/\/\S+)/g, (match, i) => (
                    <a className="cnpj_contact_us msisdn_contact_us" key={match + i} rel="noopener noreferrer" target='_blank' href={match}>
                        aqui
                    </a>
                ))}
            </p>
        </div>
    );
};

Errors.propTypes = {
    onCloseErrorMessage: PropTypes.func.isRequired,
    display: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};

export default Errors;