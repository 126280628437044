import React, { Component } from 'react'
import Collapsible from 'react-collapsible'
import Layout from '../../../layout'
import './styles.css'
import Help from './help.json'

export default class AjudaOiSeguranca extends Component {

    componentDidMount() {
        document.querySelector('chatbot').style.display = 'block';
        setTimeout(() => dispatchEvent(new Event("chatbotOiSeg")), 50);
        this.setVariablesAnalytics()
    }

    componentWillUnmount() {
        document.querySelector('chatbot').style.display = 'none';
    }

   setVariablesAnalytics(){
       let letras = [];

       for (let index = 0; index < Help.length; index++) {

           letras.push('help_oi_seg_question_{{letra}}')          
       }    
   } 

    render() {
        return (
            <React.Fragment>
                <Layout>
                    <div className="center">
                        <section className="grid-box flex column">
                            <a href="/area-logada" style={{ color: 'rgb(76, 184, 72)', fontSize: '24px', textDecoration: 'underline' }}>Voltar para Home</a>
                        </section>
                        <section className="grid-box flex column">
                            <header className="flex column" style={{ marginBottom: '1rem'}}>
                                <h1 style={{ fontSize: '2.4rem' }}>BEM-VINDO À ÁREA DO CLIENTE</h1>
                                <h1 style={{ marginTop: '3rem'}}>SOBRE OI SEGURANÇA</h1>
                            </header>
                            <section className="">
                                {Help.map((obj, key) => {
                                    return  <Collapsible className={`help_oi_seg_question_${key + 1}`}  trigger={obj.question.toUpperCase()} key={key}>
                                    <p style={{whiteSpace: 'pre-line'}}>{obj.answer}</p>
                                </Collapsible>;
                                })}
                            </section>
                        </section>
                    </div>
                </Layout>
            </React.Fragment>
        )
    }
}