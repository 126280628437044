import axios from 'axios'
import uuidv4 from 'uuid/v4'

import {
    URL_BACKEND,
    BRAND_NAME,
    REQUESTER_NAME,
    CLIENT_ID,
    CLIENT_SECRET
} from '../../constants'

const HEADERS = {
    'Content-Type': 'application/json',
    'X-FS-Correlation-ID': uuidv4(),
    'X-FS-Requester-Name': REQUESTER_NAME,
    'X-FS-Brand-Name': BRAND_NAME,
    'client_id': CLIENT_ID,
    'client_secret': CLIENT_SECRET,
    'X-FS-Is-BFF': 'true',
    'FS-ANALYTIC-ID': localStorage.getItem('user_id_custom')
}

/**
 * Function Send Pincode
 * @param {json} body 
 */
export async function sendPincode(body){
    try {
        console.log('[send-msisdn]: ', body);
        const response = await axios.post(`${URL_BACKEND}/authentication/v1/send`, body, { headers: HEADERS });
        console.log('[success-transaction-id]:', response.data.transaction_id);
        return { status: true, transaction_id: response.data.transaction_id };
    } catch (error) {
        if (error.response) {
            console.log('[error-business]: ', error.response.data);
            return { status: false, message: 'Por favor, digite um numero de celular válido!' };
        } else if (error.request) {
            console.log('[error-request]:', error.request)
            return { status: false, message: 'Ops, ocorreu um erro. Tente novamente!' };
        } else {
            console.log('[error-api]:', error.request)
            return { status: false, message: 'Ops, ocorreu um erro. Tente novamente!' };
        }
    }
}

/**
 * Function Validate Pincode
 * @param {json} body 
 */
export async function validatePincode(body){
    try {
        console.log('[validate-pincode]:', body);
        const response = await axios.post(`${URL_BACKEND}/authentication/v1/validate`, body, { headers: HEADERS });
        console.log('[success-validate-pincode]:', response.data['fs-custom-token']);
        return { status: true, fs_auth_token: response.data['fs-custom-token']};
    } catch (error) {
        if (error.response) {
            console.log('[error-business]: ', error.response.data);
            return { status: false, message: 'Código incorreto, verifique a mensagem enviada e tente novamente!' };
        } else if (error.request) {
            console.log('[error-request]:', error.request)
            return { status: false, message: 'Ops, ocorreu um erro. Tente novamente!' };
        } else {
            console.log('[error-api]:', error.request)
            return { status: false, message: 'Ops, ocorreu um erro. Tente novamente!' };
        }
    }
}

/**
 * Function Resend Pincode
 * @param {json} body 
 */
export function resendPincode(body){
    return axios.post(`${URL_BACKEND}/authentication/v1/resend`, body, { headers: HEADERS });
}