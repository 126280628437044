import React from 'react'
import PropTypes from 'prop-types';


const Footer = ({ display }) => {
    const chat_click = () => setTimeout(()=> document.getElementById('chat-icon').click(), 60);
    return (
        <section className={`modulo-contato flex ${display}`}>
            <div className="center-content flex column">
                <header className="flex column">
                    <h1>Não encontrou o que precisa?</h1>
                    <h2>Entre em contato com a gente via chat</h2>
                    <h3>* Horário de atendimento de <strong>domingo</strong> a <strong>domingo</strong> <strong>08h00</strong> às <strong>23h00</strong></h3>
                </header>
                <div className="flex flex-wrap">
                    <div className="box-contato flex column">
                        <h2>Nosso chat</h2>
                        <a href='\' onClick={chat_click} rel="noopener noreferrer" className="bt-chat">Acessar chat</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

Footer.propTypes = {
    display: PropTypes.string.isRequired,
  };
  
  export default Footer;