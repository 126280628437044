import React from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types';
import './styles.css'

const FormSendPincode = ({ display, handleSubmit, phone, handleChange, analytics}) => {
    return (
        <div className={display}>
            <header className="flex column header-form">
                <h1><strong>Acesse sua conta</strong></h1>
                <h2 style={{ fontSize: '16px', marginTop: '10px' }}>Entre com o seu telefone para ter acesso ao sistema:</h2>
            </header>
            <form className="frm-asso flex column" onSubmit={handleSubmit}>
                <div className="flex aic">
                    <label htmlFor="cel" className="aic jcc">
                        <i className="fas fa-key" />
                    </label>
                    <NumberFormat
                        value={phone}
                        onChange={handleChange}
                        type="phone"
                        name="phone"
                        placeholder="Digite seu celular"
                        required
                        format="(##) #####-####" mask="_"
                    />
                </div>

                <div style={{ display: 'flex', marginTop: '20px'}}>
                    <label htmlFor="toggle" style={{ position: 'relative', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                        <input type="checkbox" id="toggle" name="checked" required style={{ width: '41px', height: '26px' }} />
                        <span className='toogl'></span>
                    </label>

                    <small>Ao acessar o Oi Seu Negócio, você está ciente que alguns dados de sua empresa serão compartilhados com os nossos parceiros de negócio. As Informações compartilhadas são: razão social, CNPJ, número do telefone, e-mail, cidade e estado. Isso garante o acesso correto aos serviços disponíveis.</small>
                </div>

                <button className={`btn-login ${analytics}`}>Enviar</button>
            </form>
        </div>
    );
};

FormSendPincode.defaultProps = {
    analytics: ''
}

FormSendPincode.propTypes = {
    display: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    analytics: PropTypes.string
  };
  
  export default FormSendPincode;