import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

import LOGO_EGESTOR from './images/horizontal-borda.png'


const ProductsEgestor = ({ onClickAuthorizePartner }) => {
    return (
        <section className="plan-box flex column color-plan jcb">
            <div className="flex column">
                <div className="top-header flex aic jcb">
                    <small>Suas Finanças</small>
                    <Link className="help_egestor" id="bt-ajuda-erp" to="/ajuda-egestor">Ajuda</Link>
                </div>
                <header className="flex aic jcc">
                    <img src={LOGO_EGESTOR} style={{ marginRight: '1rem', width: '80px' }} className="logo-dashboard" alt="" />
                    <div className="flex column">
                        <small>Plano</small>
                        <h1>Gerenciador Digital Light</h1>
                    </div>
                </header>
            </div>
            <div style={{ marginTop: 15 }}>
                <p>Clique em “Acessar meu ERP”. Você será direcionado para a página do E-Gestor onde serão solicitados seus dados de link de acesso.</p>
                <p>As informações necessárias para login (link de acesso, nome do usuário e senha) foram enviadas para o e-mail cadastrado na Oi.</p>
            </div>
            <section className="itens-plan flex flex-wrap aic jcc">
                <a target="_blank" id="acessar-meu-erp" rel="noopener noreferrer" href="/#" onClick={e => onClickAuthorizePartner(e, 'egestor')} className="bt-plan plan_egestor">ACESSAR MEU ERP</a>
            </section>
        </section>      
    );
};

ProductsEgestor.propTypes = {   
    onClickAuthorizePartner: PropTypes.func.isRequired
  };
  
  export default ProductsEgestor;