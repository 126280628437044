import React from 'react'
import NumberFormat from 'react-number-format'
import ButtonResendPincode from '../../../../../../Buttons/ResendPincode'
import PropTypes from 'prop-types';

const FormValidatePincodeEGestor = ({ display, phone, handleSubmit, pincode, handleChange, analytics, resendPin }) => {
    return (
        <div className={display}>
            <header className="flex column header-form">
                <h1><strong>Estamos quase lá</strong></h1>
                <h2 style={{ fontSize: '16px', marginTop: '10px' }}>Digite o código enviado para o número <span style={{ color: '#00AB0F' }}>{phone}</span>.</h2>
            </header>
            <form className="frm-asso flex column" onSubmit={handleSubmit}>
                    <NumberFormat
                        value={pincode}
                        onChange={handleChange}
                        type="pincode"
                        name="pincode"
                        placeholder="Digite o código recebido"
                        maxLength={6}
                        required
                        className="product-login"
                    />
                <ButtonResendPincode resendPin={resendPin} type={'phone'} value={phone} />
                <button className={`btn-login ${analytics}`} >Validar</button>
            </form>
        </div>
    );
};

FormValidatePincodeEGestor.default = {
    analytics: '',
    resendPin: ''
}

FormValidatePincodeEGestor.propTypes = {
    display: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    pincode: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    analytics: PropTypes.string,
    resendPin: PropTypes.string
  };
  
  export default FormValidatePincodeEGestor;