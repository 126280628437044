import React from 'react'

export default () => {
    return (
        <div>
           <div className="content-center">

                <details open>
                    <summary>O que é Oi Seu Negócio?</summary>
                    
                    <div>
                        <p>Conheça as principais funcionalidades do Oi Seu Negócio no vídeo abaixo:</p>

                        <a href="https://www.youtube.com/watch?v=NUcF47PsCXQ">https://www.youtube.com/watch?v=NUcF47PsCXQ</a>
                    </div>
                </details>

                <details>
                    <summary>Como contratar o Oi Seu Negócio?</summary>
                    
                    <div>
                        <p>Para ter mais informações sobre os Combos Empresariais com os benefícios do Oi Seu Negócio inclusos e adquirir o que melhor atende as suas necessidades, <a href="https://www.oi.com.br/empresas/combo/">acesse este link</a>.</p>
                    </div>
                </details>

                <details>
                    <summary>Como acessar o Oi Seu Negócio?</summary>
                    
                    <div>
                        <p>Se você adquiriu seu combo recentemente, poderá cadastrar a sua conta após 72h da instalação completa. Se esta for a sua primeira vez no Oi Seu Negócio e já se passaram 72h da instalação, você poderá fazer o seu cadastro.</p>

                        <p>Fazendo seu cadastro:</p>

                        <p>- Acesse OiSeuNegocio.com.br e clique em “Meu Primeiro Acesso” no topo da tela</p>

                        <p>- Insira o CNPJ que foi usado na contratação do combo e clique em “Consultar”</p>

                        <p>- Insira seu e-mail e clique em “Enviar”.</p>

                        <p>- Pegue o código de 6 dígitos recebido no e-mail (não se esqueça de verificar a sua caixa de SPAM) e insira no campo indicado.</p>

                        <p>- Agora você deverá inserir o número de celular que deseja vincular ao seu cadastro. Esse número NÃO precisa ser da operadora Oi.</p>

                        <p>- Você receberá outro código de validação, dessa vez por mensagem de texto no celular (SMS). Digite esse código no campo indicado para validar.</p>

                        <p>Aguarde a finalização do procedimento. Você verá a sua área de cliente automaticamente.</p>
                    </div>
                </details>

                <details>
                    <summary>Tentei acessar mas aparece que “Não tenho produto cadastrado”. O que fazer?</summary>
                    
                    <div>
                        <p>O erro “você não tem esse produto cadastrado” pode aparecer nos seguintes casos:</p>

                        <p>- O plano que você contratou não dá direito ao Oi Seu Negócio <a href="https://www.oi.com.br/empresas/combo/">(veja mais detalhes sobre planos aqui)</a></p>

                        <p>- Você tentou fazer Login antes de se cadastrar: cadastre sua conta e tente fazer login novamente</p>

                        <p>- Você tentou se cadastrar antes de completar 72 horas da instalação do seu combo: aguarde o prazo de 72 horas (três dias) da completa instalação do seu combo para tentar o cadastro de novo.</p>

                        <p>Se mesmo assim você continuar com problemas para acessar o produto, entre em contato com o canal de atendimento empresarial da Oi <a href="https://www.oi.com.br/empresas/combo/">neste link</a>.</p>
                    </div>
                </details>

                <details>
                    <summary>Não estou recebendo o e-mail para validar o meu cadastro no Oi Seu Negócio</summary>
                    
                    <div>
                        <p>Ao fazer seu cadastro, enviamos um e-mail para a validação com um código de 6 dígitos. Se este e-mail não estiver na sua caixa de entrada, veja se digitou o e-mail corretamente e não se esqueça de checar a sua pasta de SPAM.</p>

                        <p>Se você continuar com problemas para acessar o produto, entre em contato com o canal de atendimento empresarial da Oi <a href="https://www.oi.com.br/empresas/combo/">neste link</a>.</p>
                    </div>
                </details>

                <details>
                    <summary>Não recebo SMS para validar meu acesso. O que fazer?</summary>
                    
                    <div>
                        <p>O código de 6 dígitos é usado para validar o seu número de celular durante seu acesso. Se o código não estiver chegando no seu celular, clique em “Reenviar código” para solicitar um novo.</p>

                        <p>Se isso não funcionar, verifique as seguintes alternativas:.</p>

                        <p>Verifique se o celular está com sinal. Se o sinal estiver ruim, coloque-o em modo avião e depois tire do modo avião antes de solicitar um novo código.</p>

                        <p>Veja nas configurações do seu aparelho se não há um bloqueio de recebimento de SMS (spam). Dispositivos Android e iOS possuem esses filtros que podem ser configurados.</p>

                        <p>Verifique se não há nenhum bloqueio de recebimento de SMS pela operadora.</p>

                        <p>Se você já cadastrou seu número em algum site/aplicativo de bloqueio de mensagens e ligações (Não Me Perturbe, Truecaller, Whoscall, etc.), remova esse bloquei e tente solicitar um novo código.</p>

                        <p>Se você continuar com problemas para acessar o produto, entre em contato com o canal de atendimento empresarial da Oi <a href="https://www.oi.com.br/empresas/combo/">neste link</a>.</p>
                    </div>
                </details>

                <details>
                    <summary>O código enviado via SMS é inválido</summary>
                    
                    <div>
                        <p>O código de validação de 6 dígitos enviado para o seu celular é válido por 5 minutos, após isso ele será expirado. Se você recebeu o código de validação para cadastrar a sua conta e ele está inválido, garanta que não está excedendo esse tempo. Você pode também clicar em “Reenviar código” para pedir um novo código.</p>

                        <p>Se você continuar com problemas para acessar o produto, entre em contato com o canal de atendimento empresarial da Oi <a href="https://www.oi.com.br/empresas/combo/">neste link</a>.</p>
                    </div>
                </details>

            </div>

        </div>
    );
}