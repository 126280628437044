import axios from 'axios'
import uuidv4 from 'uuid/v4'

import { 
    validateToken 
} from '../../utils/Token'

import {
    URL_BACKEND,
    BRAND_NAME,
    REQUESTER_NAME,
    CLIENT_ID,
    CLIENT_SECRET,
    URL_CHAT
} from '../../constants'

const HEADERS = {
    'Content-Type': 'application/json',
    'X-FS-Correlation-ID': uuidv4(),
    'X-FS-Requester-Name': REQUESTER_NAME,
    'X-FS-Brand-Name': BRAND_NAME,
    'client_id': CLIENT_ID,
    'client_secret': CLIENT_SECRET,
    'X-FS-Is-BFF': 'true',
    'FS-ANALYTIC-ID': localStorage.getItem('user_id_custom')
}

/**
 * Function Authorize FS Auth Token
 * @param {json} body 
 */
export async function authorization(body) {
    try {
        console.log('[send-fs-auth-token]:', body);
        let response = await axios.post(`${URL_BACKEND}/authorization/api/v1/authorize`, body, { headers: HEADERS });
        console.log('[validate-authorization]:', response.data.authorization.custom_token);
        return { status: true, custom_token: response.data.authorization.custom_token };
    } catch (error) {
        if (error.response) {
            console.log('[error-business]: ', error.response.data);
            return { status: false, message: `Ops, você não tem esse produto cadastrado. Clique ${URL_CHAT} para falar conosco!` };
        } else if (error.request) {
            console.log('[error-request]:', error.request)
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        } else {
            console.log('[error-api]:', error.request)
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        }
    }
}

/**
 * Function Validate CPNJ
 * @param {json} body 
 */
export async function validateCNPJ(body) {
    try {
        console.log('[send-cnpj]:', body);
        let response = await axios.post(`${URL_BACKEND}/authorization/api/v1/authorize/cnpj`, body, { headers: HEADERS });
        console.log('[success-cnpj-auth-token]:', response.data['fs-auth-token']);
        return { status: true, cnpj_auth_token: response.data['fs-auth-token'] };
    } catch (error) {
        if (error.response) {
            console.log('[error-business]: ', error.response.data);
            return { status: false, message: `Ops, você não tem esse produto cadastrado. Clique ${URL_CHAT} para falar conosco!` };
        } else if (error.request) {
            console.log('[error-request]:', error.request)
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        } else {
            console.log('[error-api]:', error.request)
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        }
    }
}

/**
 * Function Authorize Partner
 * @param {json} body 
 */
export async function authorizePartner(body) {
    try {
        console.log('[send-auth-token]:', body);
        let response = await axios.post(`${URL_BACKEND}/bff/api/v2/flow/authorize`, body, { headers: {
            'Content-Type': 'application/json',
            'X-FS-Correlation-ID': uuidv4(),
            'X-FS-Requester-Name': REQUESTER_NAME,
            'X-FS-Brand-Name': BRAND_NAME,
            'Authorization': `Bearer ${validateToken('token')}`
        } });
        console.log('[validate-authorization]:', response.data.data.access_url);
        return { status: true, access_url: response.data.data.access_url };
    } catch (error) {
        if (error.response) {
            console.log('[error-business]: ', error.response.data);
            return { status: false, message: `Ops, você não tem esse produto cadastrado. Clique ${URL_CHAT} para falar conosco!` };
        } else if (error.request) {
            console.log('[error-request]:', error.request)
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        } else {
            console.log('[error-api]:', error.request)
            return { status: false, message: `Ops, ocorreu um erro. Clique ${URL_CHAT} para falar conosco!` };
        }
    }
}