import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Collapsible from 'react-collapsible'
import Layout from '../../../layout'
import './styles.css'

import IMAGE1 from './images/image1.jpeg'
import IMAGE2 from './images/image2.jpeg'
import IMAGE3 from './images/image3.png'
import IMAGE4 from './images/image4.png'
import IMAGE5 from './images/image5.png'
import IMAGE6 from './images/image6.png'
import IMAGE7 from './images/image7.png'
import IMAGE8 from './images/image8.png'
import IMAGE9 from './images/image9.png'
import IMAGE10 from './images/image10.png'
import IMAGE11 from './images/image11.png'
import IMAGE12 from './images/image12.png'
import IMAGE13 from './images/image13.png'
import IMAGE14 from './images/image14.png'
import IMAGE15 from './images/image15.png'
import IMAGE16 from './images/image16.gif'
import IMAGE17 from './images/image17.gif'
import IMAGE18 from './images/image18.png'
import IMAGE19 from './images/image19.png'
import IMAGE20 from './images/image20.png'


export default class AjudaOiPostgrain extends Component {

    componentDidMount() {
        document.querySelector('chatbot').style.display = 'none';
    }

    render() {
        return (
            <React.Fragment>
                <Layout displayFooter="hidden">
                    <div className="center">
                        <section className="grid-box flex column">
                            <Link to="/area-logada" style={{ color: 'rgb(76, 184, 72)', fontSize: '24px', textDecoration: 'underline' }}>Voltar para Home</Link>
                        </section>
                        <section className="grid-box flex column">
                            <header className="flex column" style={{ marginBottom: '1rem' }}>
                                <h1 style={{ fontSize: '2.4rem' }}>BEM-VINDO À ÁREA DO CLIENTE</h1>
                                <h1 style={{ marginTop: '3rem' }}>SOBRE O POSTGRAIN</h1>
                            </header>
                            <section>
                                <Collapsible className={`help_question_1`} trigger={'Instagram: sua conta foi comprometida'.toUpperCase()} key={1}>
                                    <p>Alguns usuários estão recebendo uma notificação
                                        no aplicativo do Instagram que diz: <i style={{ fontStyle: 'italic' }}>"Parece que você compartilhou sua senha com um
                                        serviço para ajudar a obter mais curtidas ou seguidores, o que viola nossas Diretrizes
                                        da Comunidade. Altere sua senha para continuar a usar o Instagram. Se você compartilhar
                                        sua nova senha com um desses serviços, poderá ter os recursos de seguir curtir ou comentar
                                        bloqueados."</i>
                                    </p>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={IMAGE1} alt="" style={{ width: 300, marginTop: 20, marginBottom: 20 }} />
                                    </div>
                                    <p>
                                        Após algumas atualizações para combater o uso de bots e ferramentas que contribuem
                                        para o aumento de seguidores e/ou curtidas, o Instagram vem penalizando perfis que
                                        não utilizam/utilizavam tais serviços. Essas penalizações vem atingindo usuários no

                                        mundo todo, existem hoje vários artigos, vídeos e fóruns falando sobre o assunto e
                                        como &#39;resolver&#39;. Os relatos mais comuns é o não uso de bots ou ferramentas de
                                        automação, e mesmo assim o bloqueio está sendo aplicado. Além de pedir para alterar
                                        a senha, o Instagram pode aplicar bloqueios temporários.
                                    </p>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={IMAGE2} alt="" style={{ width: 300, marginTop: 20, marginBottom: 20 }} />
                                    </div>
                                    <p>Para saber mais sobre esses bloqueios, você pode ler nosso artigo clicando aqui.</p>
                                </Collapsible>

                                <Collapsible className={`help_question_2`} trigger={'Por que estou recebendo essa notificação?'.toUpperCase()} key={2}>
                                    <p>Há anos o Instagram vem trabalhando para acabar com as automações. Centenas de
                                    contas falsas já foram desativadas desde 2014, mas só agora a plataforma confirmou
                                    que iria impossibilitar o uso de ferramentas de automação, além de remover curtidas e
                                    seguidores não autênticos.
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_3`} trigger={'Em que cenário ela pode ser exibida?'.toUpperCase()} key={3}>
                                    <p>O Instagram pode exibir essa mensagem por alguns motivos:</p>
                                    <br />
                                    <p>1. Curtir, comentar e seguir/remover seguidores em um período curto de tempo;</p>
                                    <br />
                                    <p>2. Compra de seguidores e/ou curtidas;</p>
                                    <br />
                                    <p>3. Comentários genéricos, ou com marcações de vários perfis;</p>
                                    <br />
                                    <p>4. Envio de Mensagens Diretas de forma automática ou em massa;</p>
                                    <br />
                                    <p>5. Envio da mesma mensagem  para vários perfis mesmo de forma manual.</p>
                                </Collapsible>

                                <Collapsible className={`help_question_4`} trigger={'Quais ações são consideradas autênticas pelo Instagram?'.toUpperCase()} key={4}>
                                    <p>1. Ações de seguir e curtir realizadas manualmente por você ou por alguém responsável pelo perfil;</p>
                                    <br />
                                    <p>2. Escrever e enviar Mensagens Diretas de forma manual;</p>
                                    <br />
                                    <p>3. Visualizar stories manualmente.</p>
                                </Collapsible>

                                <Collapsible className={`help_question_5`} trigger={'O que fazer para evitar?'.toUpperCase()} key={5}>
                                    <p>
                                        - Para evitar que a sua conta seja excluída ou penalizada com bloqueios temporários,
                                        faça todas as ações (curtir, seguir, comentar, visualizar stories) de forma manual;
                                        <br /><br />- Não utilize serviços de automação para ganho de curtidas ou seguidores;
                                        <br /><br />- Não compartilhe a senha com muitas pessoas, utilize a função Equipe no Postgrain;
                                        <br /><br />- Caso a senha tenha sido alterada por outro dispositivo, é necessário atualizar no
                                        Postgrain.
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_6`} trigger={'O que fazer ao receber a notificação?'.toUpperCase()} key={6}>
                                    <p>Se você utiliza qualquer serviço de automação, pare imediatamente.
                                    <br /><br />- Não realize nenhuma ação (curtir, seguir, comentar, visualizar stories) no perfil por
                                    no mínimo 72h e, no máximo, 7 dias;
                                    <br /><br />- Pare de utilizar ferramentas para aumento de curtidas e seguidores;
                                    <br /><br />- Vincule o seu perfil do Instagram com o Facebook;
                                    <br /><br />- Edite a sua biografia do Instagram;
                                    <br /><br />- Na notificação de que o perfil teve a ação bloqueada, comunique o Instagram de que
                                    se trata de um erro.
                                    <br /><br />- Use outro dispositivo (celular) e mude para dados móveis.
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_7`} trigger={'Usar o Postgrain prejudica o meu perfil?'.toUpperCase()} key={7}>
                                    <p>O Postgrain preza pelo bom uso do Instagram, não concordando com qualquer tipo de
                                    SPAM e, por esse motivo, não realizamos nenhum tipo de automação. Todas as
                                    funções disponíveis dentro do Postgrain devem ser feitas por um usuário, da mesma
                                    forma que são feitas no aplicativo do Instagram.
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_8`} trigger={'Erro ao tentar se comunicar com o Instagram: entenda o que está acontecendo'.toUpperCase()} key={8}>
                                    <p>
                                        Recebeu a mensagem de que o Postgrain não está conseguindo se comunicar com o
                                        Instagram? Algumas razões podem explicar essa situação. Veja as soluções para cada
                                        caso:
                                    <br /><br />1. O Instagram está com instabilidade e, assim, o Postgrain não consegue se conectar
                                    a ele. Nesse caso, é preciso esperar a instabilidade do Instagram passar para voltar
                                    a usar o Postgrain integralmente;
                                    <br /><br />2. De tempos em tempos, a sua sessão no Postgrain expira. Para voltar a utilizar a
                                    plataforma, é preciso conectar o perfil novamente. Caso contrário, nenhuma
                                    requisição será realizada com sucesso;
                                    <br /><br />3. O Postgrain pode estar com problemas de infraestrutura. Nesse caso, nossa equipe
                                    entrará rapidamente em contato com os usuários (por email, grupo no Facebook ou
                                    pela própria plataforma) para explicar o que está havendo.
                                    <br /><br />Casos específicos também podem ocorrer. Um usuário pode conseguir agendar uma
                                    publicação com uma imagem fora dos padrões aceitos pelo Instagram, por exemplo.
                                    Nesse contexto, o usuário é notificado via email e dentro do Postgrain.
                                    <br /><br />Alguns perfis também podem ficar sem conseguir realizar nenhuma ação por conta de
                                    um bloqueio feito pelo próprio Instagram. Os motivos por trás do bloqueio são as
                                    atividades em excesso na rede.
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_9`} trigger={'Corro o risco de perder o meu perfil utilizando o Postgrain?'.toUpperCase()} key={9}>
                                    <p>
                                        Você já deve ter lido que o uso de automações não é permitido pelo Instagram pois
                                        viola as Diretrizes da Comunidade. Há um tempo, a rede social vem combatendo o uso
                                        desse tipo de ação e deixando claro que o seu fim dentro do Instagram está próximo.
                                    <br /><br /><b>O que seriam as automações?</b>
                                        <br /><br />Automação é qualquer ação de curtir, seguir, comentar e enviar mensagens realizada
                                    de forma automática e em massa com o auxilio de ferramentas. Elas normalmente são
                                    utilizadas para aumentar o número de seguidores e/ou engajamento de publicações.
                                    <br /><br />Por que essa prática é vista de forma negativa pelo Instagram?
                                    O Instagram se preocupa bastante com a experiência dos usuários e, por isso,
                                    considera nociva a utilização de ações de curtir, seguir, enviar mensagens e comentar
                                    de forma automática que podem gerar spam na rede. Para desestimular essa prática, a
                                    plataforma vem penalizando perfis.
                                    <br /><br /><b>O Postgrain realiza automação?</b>
                                        <br /><br />O Postgrain preza pelo bom uso do Instagram, não concordando com qualquer tipo de
                                    SPAM, e, por esse motivo, não realizamos nenhum tipo de automação. Todas as
                                    funções disponíveis dentro do Postgrain devem ser feitas por um usuário, da mesma
                                    forma que são feitas no aplicativo do Instagram.
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_10`} trigger={'Ação temporariamente bloqueada: entenda o porquê de o Instagram bloquear uma ação'.toUpperCase()} key={10}>
                                    <p>
                                        Recebeu a informação de que não foi possível realizar uma determinada ação no Postgrain?
                                    <br /><br />Essa mensagem é exibida quando o perfil foi notificado pelo Instagram por realizar
                                    alguma atividade que viola as Diretrizes da Comunidade. Conteúdo impróprio, uso de
                                    automações ou a mesma ação sendo realizada repetidas vezes em um curto período
                                    de tempo podem ser os motivos do bloqueio.
                                    <br /><br />A mensagem exibida pode variar de acordo com o tipo de infração das diretrizes do
                                    Instagram:
                                    <br /><br /><b>Mensagem 1:</b> Ação bloqueada. Está ação foi bloqueada. Tente novamente mais tarde.
                                    Limitamos determinados conteúdos e ações para proteger nossa comunidade.
                                    <br /><br /><b>Mensagem 2:</b> Ação temporariamente bloqueada. Parece que você estava usando esse
                                    recurso de forma indevida avançando muito rapidamente. Sua capacidade de usar o
                                    recurso foi bloqueada temporariamente. Limitamos determinados conteúdos e ações
                                    para proteger nossa comunidade.
                                    <br /><br /><b>Mensagem 3:</b> Ação bloqueada. Sua conta foi temporariamente impedida de realizar
                                    essa ação. O compartilhamento da sua conta com um serviço que ajuda a obter mais
                                    curtidas ou seguidores viola nossas Diretrizes da Comunidade. Esse bloqueio expirará
                                    em XX-XX-XXXX. Informe-nos se você acha que cometemos um engano.
                                    <br /><br />É muito importante seguir as diretrizes do site para evitar que o seu perfil seja
                                    temporariamente bloqueado.
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_11`} trigger={'Legenda: entenda o porquê de o Instagram remover legendas e comentários'.toUpperCase()} key={11}>
                                    <p>
                                        Realizou um Novo Post, Novo Carrossel ou Novo IGTV e percebeu que a legenda ou o
                                        primeiro comentário não apareceu? Isso significa que ele pode ter sido removido pelo
                                        próprio Instagram.
                                    <br /><br />Mas por que isso acontece?
                                    <br /><br />O Instagram se preocupa bastante com a experiência dos usuários e vem realizando
                                    atualizações para continuar proporcionando um ambiente agradável. Por isso,
                                    considera nocivo a utilização de ações de curtir, seguir, enviar mensagens e comentar
                                    de forma automática que podem gerar spam na rede. Para desestimular essa prática, a
                                    plataforma vem penalizando perfis.
                                    <br /><br />Quais são as penalizações?
                                    <br /><br />1. Bloqueios temporários nas ações curtir, seguir, enviar mensagens, comentar e até
                                    mesmo realizar novas publicações;
                                    <br /><br />2. Remoção de legendas e comentários;
                                    <br /><br />3. Desativação do perfil.
                                    <br /><br />É importante seguir as diretrizes do site para evitar essas penalizações.
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_12`} trigger={'Por que não consigo adicionar o meu perfil?'.toUpperCase()} key={12}>
                                    <p>
                                        Identificamos que recentemente que o Instagram realizou uma atualização que está
                                        impedindo que alguns perfis sejam adicionados no Postgrain. Os perfis que não exibem
                                        o &quot;Fui eu&quot; no Instagram Web, nem solicita o envio do código por SMS ou e-mail,
                                        podem não conseguir concluir o processo de adicionar perfil mesmo que realize
                                        tentativas consecutivas.
                                    <br /><br />É importante ressaltar que o erro não ocorre com todos os perfis, e existe a
                                    possibilidade de conseguir adicionar o perfil após algum tempo.
                                    <br /><br />Nossa equipe técnica está ciente desse problema e trabalhando para possibilitar
                                    novamente a conexão dos perfis. Contudo, ainda não temos previsão para
                                    normalização.
                                    <br /><br />A equipe Postgrain agradece a compreensão e pede desculpas pelo transtorno
                                    causado.
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_13`} trigger={'É possível excluir o cadastro do Postgrain?'.toUpperCase()} key={13}>
                                    <p>
                                        Por não continuar com a sua conta no Postgrain, é necessário realizar o cancelamento
                                        da assinatura (se possuir assinatura ativa), remover os perfis, realizar logout e não
                                        mais acesse a conta.
                                    <br /><br />Após realizar esse processo, em até 48hrs, a conta ficará inativa. Caso volte a realizar o
                                    login dentro desse prazo, o processo será interrompido.
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_14`} trigger={'Como remover um perfil do Postgrain'.toUpperCase()} key={14}>
                                    <p>
                                        Adicionou um perfil, mas precisa removê-lo? É simples:
                                    <br /><br />1 - Selecione o perfil na barra superior esquerda da ferramenta:
                                            <img src={IMAGE3} alt="" style={{ textAlign: 'center', width: 300, marginTop: 20, marginBottom: 20 }} />
                                    </p>
                                    <p>
                                        2 - Clique em Configurações do perfil que fica abaixo de Relatórios:
                                            <img src={IMAGE4} alt="" style={{ textAlign: 'center', width: 300, marginTop: 20, marginBottom: 20 }} />
                                    </p>
                                    <p>
                                        3 - Para finalizar, basta selecionar a opção Remover:
                                        <img src={IMAGE5} alt="" style={{ textAlign: 'center', width: 500, marginTop: 20, marginBottom: 20 }} />
                                    </p>
                                    <p>
                                        Feito. Perfil removido com sucesso!</p>
                                    <br /><br />
                                        <h3>Importante</h3>
                                        <br />
                                    <p>Caso a opção &quot;Configurações do perfil&quot; não apareça pra você, basta diminuir o zoom
                                    da tela para 80%.
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_15`} trigger={'Equipe: como adicionar membros'.toUpperCase()} key={15}>
                                    <p>
                                        Ter muitos perfis para administrar sozinho(a) é complicado, principalmente, com a
                                        intensa demanda de trabalho do dia a dia, não é verdade?
                                    <br /><br />No Postgrain, o usuário pode inserir membros na conta para gerenciar os perfis.E para
                                    fazer isso é muito simples, segue esse passo a passo que não tem erro:
                                    </p>
                                    <br />
                                    <b>1. Clique no seu nome no canto superior da tela &gt; Em seguida, clique em Configurações:</b>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={IMAGE6} alt="" style={{ width: 300, marginTop: 20, marginBottom: 20 }} />
                                    </div>
                                    <b>2. Selecione a aba Equipe &gt; Depois clique em Adicionar membro:</b>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={IMAGE7} alt="" style={{ width: 500, marginTop: 20, marginBottom: 20 }} />
                                    </div>
                                    <b>3 - Adicione o email do usuário &gt; Selecione os perfis (Todos ou Escolher) aos quais o membro terá acesso &gt; Para finalizar, clique em Adicionar.</b>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={IMAGE8} alt="" style={{ width: 500, marginTop: 20, marginBottom: 20 }} />
                                    </div>
                                    <p>
                                        Logo em seguida, o membro receberá o convite no email. Para participar da equipe,
                                        basta aceitar o convite ou confirmar direto pela plataforma.
                                    <br /><br />Pronto! Membro adicionado!
                                    </p>
                                    <p>
                                        <br />
                                        <b>
                                            Como o membro tem acesso ao perfil?
                                    </b>
                                        <br /><br />Após aceitar o convite, o membro deve acessar o Postgrain com a conta dele. Para
                                    começar a gerenciar os perfis aos quais foi autorizado, deve clicar no nome dele,
                                    presente na tela inicial, no canto direito. Depois, basta selecionar o perfil da conta
                                    (Admin) que enviou o convite.
                                        <img src={IMAGE9} alt="" style={{ textAlign: 'center', width: 300, marginTop: 20, marginBottom: 20 }} />
                                    </p>
                                    <p>
                                        <br />
                                        <b>É possível remover um membro? </b>
                                        <br /><br />Sim! Para remover um membro da equipe, siga o passo a passo:
                                    <br /><br />
                                        <b>1 - Clique no seu nome no canto superior da tela &gt; Em seguida, clique em
                                    Configurações:</b>
                                            <img src={IMAGE11} alt="" style={{ textAlign: 'center', width: 300, marginTop: 20, marginBottom: 20 }} />
                                        <b>2 - Selecione a aba Equipe:</b>
                                            <img src={IMAGE10} alt="" style={{ textAlign: 'center', width: 500, marginTop: 20, marginBottom: 20 }} />
                                    Na janela ficam os emails dos membros da equipe e há duas opções
                                    disponíveis: Alterar Permissões e Excluir.
                                    <br /><br /><b>- Alterar Permissões</b> - o usuário pode alterar os perfis que o membro tem acesso.
                                    <br /><br /><b>- Excluir</b> - Remove o membro da equipe.
                                    <br /><br />Então, para remover o membro da sua equipe, basta buscar o email do usuário no
                                    painel e clicar em excluir. Feito!
                                    <br /><br />Com o trabalho compartilhado fica muito mais fácil de alcançar os objetivos do dia e
                                    satisfazer o cliente, não é mesmo?!
                                    <br /><br />Junte a sua equipe, una forças e mãos à obra!
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_16`} trigger={'É possível alterar a foto do perfil pelo Postgrain?'.toUpperCase()} key={16}>
                                    <p>
                                        Todos os dados relacionados ao perfil, assim como a imagem, só podem ser alterados
                                        no aplicativo do Instagram. Através do Postgrain não é possível realizar nenhuma
                                        alteração em relação ao seu perfil do Instagram.
                                    <br /><br />Porém, caso sejam alterações relacionadas à conta do Postgrain, é possível.
                                    <br /><br /><b>Como altero o nome de apresentação no canto superior?</b>
                                        <br /><br />Fácil, é só seguir esse passo a passo:
                                    <br /><br /><b>1 - Clique em Configurações (no canto superior da tela);</b>
                                            <img src={IMAGE11} alt="" style={{ textAlign: 'center', width: 300, marginTop: 20, marginBottom: 20 }} />
                                        <br /><br /><b>2 - No painel de Configurações da conta &gt;Acesse a aba Dados da organização &gt; Clique
                                    em Editar dados do usuário.</b>
                                            <img src={IMAGE12} alt="" style={{ textAlign: 'center', width: 500, marginTop: 20, marginBottom: 20 }} />
                                        <br />Só não esquece de apertar em &quot;Atualizar Dados&quot; quando acabar, tá?
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_17`} trigger={'E-mail e senha de acesso: como alterar'.toUpperCase()} key={17}>
                                    <p>
                                        Dependendo da empresa, a rotatividade de funcionários pode ser grande e, em certos
                                        casos, imprevisível. Assim, a mudança de dados e informações pode ser recorrente.
                                    <br /><br /><b>Preciso mudar o email de cadastro. Como faço?</b>
                                        <br /><br />Há duas formas de alterar esse dado:
                                    <br /><br /><b>Primeira forma:</b>
                                        <br /><br />1. Você acessa Configurações (Canto superior da tela):
                                            <img src={IMAGE6} alt="" style={{ textAlign: 'center', width: 300, marginTop: 20, marginBottom: 20 }} />
                                        <br /><br />2. Dados do Usuário &gt; Editar dados do usuário:
                                            <img src={IMAGE13} alt="" style={{ textAlign: 'center', width: 600, marginTop: 20, marginBottom: 20 }} />
                                        <br /><br />3. No painel de Dados do Usuário você vai poder alterar:
                                    <br /><br />Nome;
                                    <br />Email;
                                    <br />País;
                                    <br />Idioma;
                                    <br />Fuso horário.
                                    <br /><br />Como também, logo abaixo, alterar a Senha no painel de Dados de Acesso.
                                            <img src={IMAGE14} alt="" style={{ textAlign: 'center', width: 300, marginTop: 20, marginBottom: 20 }} />
                                        <br /><br /><b>Segunda forma:</b>
                                        <br /><br />Em casos de erro ou se mesmo seguindo o passo a passo o usuário não consiga alterar
                                    email ou senha de acesso, é possível entrar em contato com o Suporte no botão de
                                    ajuda fica dentro do símbolo de interrogação que aparece no canto superior direito:
                                            <img src={IMAGE15} alt="" style={{ textAlign: 'center', width: 800, marginTop: 20, marginBottom: 20 }} />
                                        <br /><br /><b>Mudança de email:</b>
                                        <br /><br />Para realizar a mudança através do suporte, é necessário enviar um email
                                    para support@postgrain.com com os seguintes dados:
                                    <br /><br />- Email atual;
                                    <br />- Email novo;
                                    <br />- Username de todos os perfis vinculados à conta;
                                    <br />- Cópia do seu RG anexada.

                                    <br /><br /><b>Mudança de Senha:</b>
                                        <br /><br />Para realizar a mudança através do suporte, é necessário enviar um email
                                    para support@postgrain.com com os seguintes dados:
                                    <br /><br />- Email atual;
                                    <br />- Informar o email da conta;
                                    <br />- Confirmar o username de todos os perfis vinculados à conta;
                                    <br /><br />Prontinho! Agora você já sabe como realizar a mudança de dados na sua conta!
                                    <br /><br />Até mais!
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_18`} trigger={'Qual o melhor navegador para utilizar o Postgrain?'.toUpperCase()} key={18}>
                                    <p>
                                        O Postgrain é compatível com qualquer navegador, contudo, o seu desempenho é bem
                                    melhor no Chrome. Por esse motivo, recomendamos fortemente o uso do <b>Google
                                    Chrome.</b>
                                    </p>
                                    <br />
                                    <h3><strong>Dica</strong></h3>
                                    <br />
                                    <p>É importante manter o navegador sempre atualizado, e limpar os dados de
                                    navegação (cache) com frequência.</p>
                                    <p>
                                        <br />
                                    Caso esteja enfrentando algum problema no carregamento de imagens/vídeos, basta
                                    entrar em contato com o nosso suporte através do &#39;Chat em tempo real&#39; ou no botão
                                    &#39;Deixar uma mensagem&#39;.
                                    </p>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={IMAGE15} alt="" style={{ width: 800, marginTop: 20, marginBottom: 20 }} />
                                    </div>
                                </Collapsible>

                                <Collapsible className={`help_question_19`} trigger={'Como entrar em contato com o Suporte do Postgrain?'.toUpperCase()} key={19}>
                                    <p>
                                        Está com alguma dúvida ou enfrentando problemas com o Postgrain? Fica tranquilo(a)
                                        que estamos aqui para te ajudar!</p>
                                    <br />
                                    <p>
                                        Preparamos artigos sobre a ferramenta para te guiar na utilização do Postgrain.</p>
                                    <br />
                                    <p>
                                        <strong>Como acessar a Central de ajuda?</strong>
                                    </p>
                                    <br />
                                    <p>
                                        1 - O botão de ajuda do Postgrain mudou de lugar! Agora, para deixar a tela do usuário
                                        mais limpa e organizada, nosso botão de ajuda fica dentro do símbolo de interrogação
                                        que aparece no canto superior direito:</p>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={IMAGE15} alt="" style={{ width: 800, marginTop: 20, marginBottom: 20 }} />
                                    </div>
                                    <br />
                                    <p>
                                        2 - Será exibido a tela inicial onde o usuário poderá pesquisar artigos relacionados a sua dúvida:
                                    </p>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={IMAGE16} alt="" style={{ width: 600, marginTop: 20, marginBottom: 20 }} />
                                    </div>
                                    <br />
                                    <strong>Observação:</strong>
                                    <br /><br />
                                    <p>
                                        Caso a informação que procura não esteja nas principais sugestões, basta você digitar
                                        uma palavra referente a sua dúvida na barra de pesquisa, que será exibido os artigos
                                        relacionados.</p>
                                    <br />
                                    <strong>Não encontrou a informação que procurava na nossa Central de Ajuda?</strong>
                                    <br /><br />
                                    <p>Você poderá nos contactar através dos canais:</p>
                                    <br />- Dentro da janelinha de Ajuda, terá a opção Chat em Tempo Real ou Deixar uma mensagem.
                                    <br />- O Ticket aberto através da opção Deixar uma mensagem tem um prazo de 1 dia útil
                                    para ser respondido pela nossa equipe.
                                    <br />- O chat online funciona das 8h às 18:45h de segunda à sexta, podendo tirar suas
                                    dúvidas em tempo real com um dos nossos agentes de uma forma dinâmica e
                                    confortável.
                                    <br />- Pode entrar em contato via e-mail
                                    para support@postgrain.com ou support@postgrain.zendesk.com também podendo
                                    levar até 1 dia útil para ser respondido.
                                    <br />- Página oficial no Facebook e o grupo oficial Entusiastas do Postgrain (também no Facebook).
                                    <br /><br /><br />
                                    <p>
                                        Ao abrir um Ticket, você receberá as notificações dentro da ferramenta de que o seu
                                        chamado foi atualizado e também receberá via e-mail com o conteúdo da sua
                                        solicitação e resposta dos nossos agentes.
                                    </p>
                                </Collapsible>

                                <Collapsible className={`help_question_20`} trigger={'Como entrar em contato com o suporte do Instagram?'.toUpperCase()} key={20}>
                                    <p>
                                        Você está tendo algum problema com a sua conta do Instagram? Em primeiro lugar, vá
                                        até a Central de Ajuda da plataforma. Por lá, é possível acessar listas com as dúvidas
                                        mais frequentes. Assim, é possível que você consiga resolver seu problema sem
                                        precisar entrar em contato com a equipe de suporte do Instagram.
                                    </p>
                                    <br />
                                    <p>
                                        O Instagram não possui um serviço de suporte muito aberto ao público. Por isso,
                                        vamos te mostrar neste artigo as melhores maneiras de conseguir entrar em contato
                                        com eles e relatar seu problema ou erro.
                                    </p>
                                    <br />
                                    <p>
                                        Como a maioria das empresas focadas em internet, o Instagram não disponibiliza um
                                        número de suporte via telefone.
                                    </p>
                                    <br />
                                    <p>
                                        E suporte por email? Também não é um contato possível. A maneira que eles utilizam
                                        para a comunicarem é pela Central de Ajuda ou quando o usuário consegue chamar a
                                        atenção da plataforma pelas redes sociais, como Facebook e Twitter.
                                    </p>
                                    <br />
                                    <h3><strong>Pelo aplicativo do Instagram</strong></h3>
                                    <br />
                                    <p>
                                        Se você consegue logar seu perfil no Instagram, e precisa relatar um problema técnico,
                                        é possível entrar em contato pelo próprio aplicativo. Basta seguir esses passos:
                                    </p>
                                    <br />
                                    <p>1. Vá até seu perfil e deslize a tela para o lado esquerdo;</p>
                                    <p>2. Selecione a opção Configurações, Suporte e em seguida Reportar um problema;</p>
                                    <p>3. Toque em “Algo não está funcionando” e explique o problema que você está tendo com o máximo de detalhes possível.</p>
                                    <br />
                                    <p>
                                        Você receberá uma mensagem automática, confirmando o acesso ao perfil ou pedindo
                                        documentos que comprovem os dados. Não existe um tempo pré definido para a
                                        resposta do suporte, mas a comunicação acontece sempre pelo email cadastrado.
                                    </p>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={IMAGE17} alt="" style={{ width: 300, marginTop: 20, marginBottom: 20 }} />
                                    </div>
                                    <br />
                                    <h3><strong>Pelo Facebook</strong></h3>
                                    <br />
                                    <p>
                                        No caso de você não ter acesso ao seu perfil do Instagram, a única maneira é
                                        pela “Ajuda Para Desktop” do Facebook. Se sua conta foi desativada por motivos que
                                        você desconhece, basta acessar o link e preencher o formulário (como na foto abaixo)
                                        e o problema será investigado pela equipe de suporte do Instagram.
                                    </p>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={IMAGE18} alt="" style={{ width: 600, marginTop: 20, marginBottom: 20 }} />
                                    </div>
                                    <br />
                                    <h3><strong>Redes Sociais e suporte do Instagram</strong></h3>
                                    <br />
                                    <p>
                                        Como boa empresa focada em internet, as redes sociais não poderiam ficar de
                                        fora. Esta parece ser a maneira mais efetiva de enviar mensagens diretas ao suporte
                                        do Instagram, visto que não tem e-mail nem telefone de suporte. A primeira opção é
                                        por mensagem direta no perfil oficial do Instagram.
                                    </p>
                                    <br />
                                    <p>
                                        A segunda opção é por meio do Twitter, marcando a empresa e descrevendo seu
                                        problema eles te ajudarão, de alguma forma, a resolver o problema em questão.
                                    </p>
                                    <br />
                                    <h3><strong>Reclame aqui</strong></h3>
                                    <br />
                                    <p>
                                        Atualmente, a forma mais eficaz de obter respostas do Instagram é pelo Reclame Aqui.
                                        Só pra você ter uma ideia, no momento em que escrevemos esse artigo, a rede social
                                        tem uma taxa de resposta de 99,9% no site.
                                    </p>
                                    <br />
                                    <p>
                                        E não é por acaso: pega muito mal pra qualquer empresa ter uma baixa avaliação no
                                        site, que serve como um fórum de reclamações sobre atendimento, compra, produtos
                                        e serviços das empresas que funcionam no Brasil. Nesse caso, os brasileiros saem na
                                        frente. Afinal, esse parece ser o único canal que o Instagram se faz presente com
                                        regularidade.
                                    </p>
                                    <br />
                                    <p>
                                        Para enviar uma reclamação, basta entrar no link do Instagram no Reclame Aqui e
                                        clicar no botão Reclamar, que fica no canto direito. Descreva seu problema
                                        detalhadamente e, se possível, insira uma imagem. Pronto! Agora é só esperar.
                                    </p>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={IMAGE19} alt="" style={{ width: 600, marginTop: 20, marginBottom: 20 }} />
                                    </div>
                                    <p>A resposta costuma chegar dentro de uma semana. Você será avisado por e-mail
                                    juntamente com a resposta que receberá diretamente no Reclame Aqui.</p>
                                    <br />
                                    <p><strong>Dica:</strong> antes de deixar a sua reclamação, faça uma busca no Reclame Aqui para saber se
                                    essa dúvida já foi respondida pelo próprio Instagram. Assim, você consegue resolver o
                                    problema rapidamente, sem precisar esperar uma resposta.</p>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={IMAGE20} alt="" style={{ width: 600, marginTop: 20, marginBottom: 20 }} />
                                    </div>
                                </Collapsible>

                            </section>
                        </section>
                    </div>
                    <section className="modulo-contato flex">
                        <div className="center-content flex column">
                            <div className="flex flex-wrap">
                                <div className="box-contato flex column">
                                    <h2>Escreva para:</h2>
                                    <a href="mailto:support@postgrain.com">support@postgrain.com</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            </React.Fragment>
        )
    }
}