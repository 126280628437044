import React from "react";
import ICON_APPLE_STORE from "../../../images/icon-apple-store.png";
import { Button } from "./styled";

const ButtonAppleStore = ({ url }) => {
  return (
    <Button href={url} target="_blank" rel="noopener noreferrer">
      <img src={ICON_APPLE_STORE} alt="" width={40} height={40} /> Acesse o APP
    </Button>
  );
};

export default ButtonAppleStore;
