import emailValidator from 'email-validator'

/**
 * Function Validator Email
 * @param {int} email
 */
export function validatorEmail(email) {
    if (emailValidator.validate(email) === false) {
        return { status: false, message: 'Por favor, digite um endereço de e-mail válido!' };
    }

    return { status: true, email: email };
}