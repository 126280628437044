import React from 'react'

export default () => {
    return (
        <div>
            <div className="content-center">

                <details open>
                    <summary>O que é?</summary>
                    
                    <div>
                        <p>Olist é a única solução do mercado que escala as suas vendas em tempo recorde nos marketplaces. Somos um sistema de vendas completo que contempla contratos exclusivos com marketplaces, Correios, transportadoras privadas, ERPs e plataformas de e-commerce.</p>
                    </div>
                </details>

                <details>
                    <summary>Como Funciona?</summary>
                    
                    <div>
                        <p>Olist é uma loja oficial de altíssima reputação que está presente nos maiores canais de vendas do Brasil. O principal benefício está em utilizar a visibilidade do Olist Store e garantir melhores oportunidades de vendas para seus produtos, além de benefícios logísticas, SAC, campanhas e mais!</p>
                    </div>
                </details>

                <details>
                    <summary>Regulamento da Oferta</summary>
                    
                    <div>
                        <p>É necessário possuir CNPJ. O cliente deve ter uma empresa aberta e regularizada.</p>
                        <p>Oferta exclusiva para clientes pessoa jurídica com micro e pequenas empresas com oferta Oi Fibra ativa contratada a partir de 09/02/2021. O acesso será liberado em até 72h após a instalação e ficará disponível por 1 ano. O acesso ao “Olist” está sujeito à aprovação. Consulte o regulamento.</p>
                    </div>
                </details>

            </div>

        </div>
    );
}