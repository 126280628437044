import axios from "axios";
import uuidv4 from "uuid/v4";
import { validateToken } from "../../utils/Token";

import { URL_BACKEND, REQUESTER_NAME, BRAND_NAME } from "../../constants";

/**
 * Function Get Packs
 */
export async function getPacks() {
  try {
    console.log("[get-packs]: oi");

    const response = await axios.get(`${URL_BACKEND}/bff/api/v2/packs`, {
      headers: {
        "Content-Type": "application/json",
        "X-FS-Correlation-ID": uuidv4(),
        "X-FS-Requester-Name": REQUESTER_NAME,
        "X-FS-Brand-Name": BRAND_NAME,
        Authorization: `Bearer ${validateToken("token")}`,
        "FS-ANALYTIC-ID": localStorage.getItem("user_id_custom"),
      },
    });

    let products = [];
    let pom = [];
    let egestor = [];
    let oiads = [];
    let olist = [];

    localStorage.setItem("user-cnpj", response.data.user.cnpj);

    response.data.products.forEach((row) => {
      row.items.forEach((rows) => {
        switch (rows.name) {
          case "CL":
            return pom.push({
              id: rows.vendor.id,
              vendor: rows.vendor.name,
              name: "Proteção Sync",
              licenses_size: rows.licenses.size,
              licenses_unit: rows.licenses.unit,
            });

          case "SEG":
            return pom.push({
              id: rows.vendor.id,
              vendor: rows.vendor.name,
              name: "Segurança Completa",
              licenses_size: rows.licenses.size,
              licenses_unit: rows.licenses.unit,
            });

          case "PAR":
            return pom.push({
              id: rows.vendor.id,
              vendor: rows.vendor.name,
              name: "Proteção Completa",
              licenses_size: rows.licenses.size,
              licenses_unit: rows.licenses.unit,
            });

          case "EG":
            return egestor.push({
              id: rows.vendor.id,
              vendor: rows.vendor.name,
              name: "eGestor",
              licenses_size: rows.licenses.size,
              licenses_unit: rows.licenses.unit,
            });

          case "ADS":
            return oiads.push({
              id: rows.vendor.id,
              vendor: rows.vendor.name,
              name: "OiAds",
              licenses_size: rows.licenses.size,
              licenses_unit: rows.licenses.unit,
            });

          case "PG":
            return oiads.push({
              id: rows.vendor.id,
              vendor: rows.vendor.name,
              name: "Postgrain",
              licenses_size: rows.licenses.size,
              licenses_unit: rows.licenses.unit,
            });

          case "OLIST":
            return olist.push({
              id: rows.vendor.id,
              vendor: rows.vendor.name,
              name: "OList",
              licenses_size: rows.licenses.size,
              licenses_unit: rows.licenses.unit,
            });
          default:
            break;
        }
      });
    });

    if (typeof pom !== "undefined" && pom.length > 0) {
      products.push({ pom: pom });
    }

    if (typeof egestor !== "undefined" && egestor.length > 0) {
      products.push({ egestor: egestor });
    }

    if (typeof oiads !== "undefined" && oiads.length > 0) {
      products.push({ oiads: oiads });
    }

    if (typeof olist !== "undefined" && olist.length > 0) {
      products.push({ olist: olist });
    }

    console.log("[success-packs]:", products);
    return {
      status: true,
      products: products,
      user: response.data.user,
      token: validateToken("token"),
    };
  } catch (error) {
    if (error.response) {
      console.log("[error-business]: ", error.response.data);
      return { status: false };
    }

    if (error.request) {
      console.log("[error-request]:", error.request);
      return { status: false };
    }

    console.log("[error-api]:", error.request);
    return { status: false };
  }
}

/**
 * Function Activate Account
 * @param {json} body
 */
export async function activateAccount(body) {
  try {
    console.log("[send-activate-pagseguro]:", body);
    const response = await axios.post(
      `${URL_BACKEND}/bff/api/v2/pre-sale/merge-activate`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "X-FS-Correlation-ID": uuidv4(),
          "X-FS-Requester-Name": REQUESTER_NAME,
          "X-FS-Brand-Name": BRAND_NAME,
          Authorization: `Bearer ${validateToken("token")}`,
          "FS-ANALYTIC-ID": localStorage.getItem("user_id_custom"),
        },
      }
    );
    console.log("[success-send-activate-pagseguro-tasting]:", response.data);
    return { status: true };
  } catch (error) {
    if (error.response) {
      console.log("[error-business]: ", error.response.data);
      return { status: false };
    }

    if (error.request) {
      console.log("[error-request]:", error.request);
      return { status: false };
    }

    console.log("[error-api]:", error.request);
    return { status: false };
  }
}
