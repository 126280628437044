import React, { Component } from 'react'
import { Loading } from '../../../components'
import { validateToken, removeToken } from '../../../utils/Token'
import { maskCNPJ } from '../../../utils/Mask/CNPJ'
import { getPacks } from '../../../services'

export default class ButtonLogout extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();
        this.state = {
            loading: false,
            cnpj: 'CARREGANDO...'
        };

        this.handleLogoutClick = this.handleLogoutClick.bind(this);
    }

    componentDidMount(){
        this.getPacks();
    }

    /**
     *  Get Packs
     */
    async getPacks(){
        let response = await getPacks();

        if (!response.status) {
            this.setState({
                loading: false,
                error: true,
                cnpj: null
            });
            return;
        }

        

        this.setState({
            loading: false,
            cnpj: maskCNPJ(response.user.cnpj),
        });        
    }

    /**
    * Handle Click Logout
    */
    handleLogoutClick(e) {
        e.preventDefault();

        if(validateToken('token')){
            console.log('remove session token user', true);
            this.setState({ 
                loading: true
            });

            setTimeout(function () {
                removeToken();
            }, 3000);
        }

        localStorage.removeItem('user-cnpj')
    }

    /**
    * Render Component
    */
    render() {
        const { loading, cnpj } = this.state;

        return (
            <>
                <Loading display={loading} />
                <p className="cnpj-dashboard">
                    <strong>{cnpj}</strong>
                </p>                
                <p className="sair">
                    <span style={{ color: 'hsla(0, 0%, 40%, 1)', letterSpacing: '.04rem', textDecoration: 'none', cursor: 'pointer' }} onClick={this.handleLogoutClick}>Sair</span>
                </p>
            </>
        );
    }
}