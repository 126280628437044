import React from 'react'

export default () => {
    return (
        <div>
           <div className="content-center">

                <details open>
                    <summary>O que é?</summary>
                    
                    <div>
                        <p>O Gestor e-finanças é o sistema de gestão empresarial para micro e pequenas empresas para otimizar a gestão financeira, operacional e logística do cliente. Um ERP online pensado para a realidade do empreendedor brasileiro.</p>
                    </div>
                </details>

                <details>
                    <summary>Como funciona?</summary>
                    
                    <div>
                        <p>Funciona em nuvem, ou seja, é totalmente online. Não precisa instalação, podendo ser acessado através do navegador e em qualquer sistema operacional. Basta ter acesso a internet.</p>
                    </div>
                </details>

            </div>

        </div>
    );
}