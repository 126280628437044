import React from 'react'

export default () => {
    return (
        <div>
           <div className="content-center">

                <details open className="oi_seguranca-o_que_e">
                    <summary>O que é?</summary>
                    
                    <div>
                        <p>O Oi Segurança deixa sua experiência na internet mais incrível! Com ele você faz compras online e transações bancárias livre de vírus; protege o que seus filhos fazem na internet; acelera o seu celular e aumenta a duração da bateria; e ainda guarda fotos, vídeos e arquivos na nuvem com total segurança.</p>
                    </div>
                </details>

                <details className="oi_seguranca-como_funciona">
                    <summary>Como Funciona?</summary>
                    
                    <div>
                        <p>O Oi Segurança é bem simples e intuitivo. Basta fazer o Download do aplicativo, fazer o seu login e decidir como usará suas funcionalidades.</p>
                    </div>
                </details>

            </div>

        </div>
    );
}