import React from 'react'

import SGEGESTOR1 from './images/1.jpg'
import SGEGESTOR2 from './images/2.jpg'
import SGEGESTOR3 from './images/3.jpg'
import SGEGESTOR4 from './images/4.jpg'
import SGEGESTOR5 from './images/5.jpg'

export default () => {
    return (
        <div>
            <div className="secundary-gallery">
                <div className="content-center">
                    <ul className="list-secundary-gallery owl-carousel owl-theme">
                        <li>
                            <div className="sg-header">
                                <p className="_title">Controle Financeiro</p>

                                <p>Contas a pagar e receber com emissão de boleto direto da plataforma.</p>
                            </div>

                            <div className="_img">
                                <img src={SGEGESTOR1} alt="Imagem Galeria Secundária" />
                            </div>
                        </li>

                        <li>
                            <div className="sg-header">
                                <p className="_title">Visão em tempo real do seu Estoque</p>

                                <p>Produtos demais é dinheiro parado, e pouco estoque pode fazer você perder vendas. Agora você sabe em tempo real a situação e a necessidade do seu estoque.</p>
                            </div>

                            <div className="_img">
                                <img src={SGEGESTOR2} alt="Imagem Galeria Secundária" />
                            </div>
                        </li>

                        <li>
                            <div className="sg-header">
                                <p className="_title">Mais atenção aos seus Clientes</p>

                                <p>Tenha em único lugar todos os cadastros de clientes, fornecedores e transportadores.</p>
                            </div>

                            <div className="_img">
                                <img src={SGEGESTOR3} alt="Imagem Galeria Secundária" />
                            </div>
                        </li>

                        <li>
                            <div className="sg-header">
                                <p className="_title">Relatórios</p>

                                <p>Relatórios com visão estratégica para você fazer seu planejamento.</p>
                            </div>

                            <div className="_img">
                                <img src={SGEGESTOR4} alt="Imagem Galeria Secundária" />
                            </div>
                        </li>

                        <li>
                            <div className="sg-header">
                                <p className="_title">Visão de suas Vendas</p>

                                <p>Melhor gestão de suas vendas com emissão de boletos e Notas Fiscais com um clique.</p>
                            </div>

                            <div className="_img">
                                <img src={SGEGESTOR5} alt="Imagem Galeria Secundária" />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}