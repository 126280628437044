import React, { Component } from 'react'
import { validatorPhoneNumber } from '../../../../../utils/Validator/PhoneNumber'
import { createToken } from '../../../../../utils/Token'
import {
    sendPincode,
    validatePincode,
    authorization,
    verifyCustomToken,
    getPacks,
    authorizePartner
} from '../../../../../services'

import {
    ErrorMessage,
    Loading
} from '../../../../../components'

import FormSendPincodeEGestor from '../../../../../components/Forms/Modal/Login/SendPincode/Home/EGestor'
import FormValidatePincodeEGestor from '../../../../../components/Forms/Modal/Login/ValidatePincode/Home/EGestor'

export default class ModalLogin extends Component {

    /**
     * Constructor
     */
    constructor() {
        super();

        this.state = {
            loading: false,
            errorMessage: '',
            displayErrorMessage: 'none',
            displayModal: 'hidden',
            displayFormSendPincodeEGestor: 'show',
            displayFormValidatePincodeEGestor: 'hidden',
            phone: '',
            transaction_id: '',
            pincode: '',
            fs_custom_token: '',
            custom_token: '',
            redirect: false
        }

        this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this); 
        this.handleChangeFormSendPincodeEGestor = this.handleChangeFormSendPincodeEGestor.bind(this);
        this.handleSubmitFormSendPincodeEGestor = this.handleSubmitFormSendPincodeEGestor.bind(this);
        this.handleChangeFormValidatePincodeEGestor = this.handleChangeFormValidatePincodeEGestor.bind(this);
        this.handleSubmitFormValidatePincodeEGestor = this.handleSubmitFormValidatePincodeEGestor.bind(this);
    }

    /**
     * On Click Modal Display
     */
    onClickModalDisplay = (v) => {
        switch (v) {
            case true:
                this.setState({
                    displayModal: 'show',
                });
                break;

            default:
                let err = document.getElementsByClassName('div-error');

                for (var i = 0; i < err.length; i++) {
                    err[i].style.display = 'none';
                }

                this.setState({
                    displayModal: 'hidden',
                    displayFormSendPincodeEGestor: 'show',
                    displayFormValidatePincodeEGestor: 'hidden'
                });
                break;
        }
    }

    /**
     * On Close Error Message
     */
    onCloseErrorMessage = (e) => {
        this.setState({ displayErrorMessage: e, errorMessage: '' });
    }

    /**
     * Handle Change Form Send Pincode
     * @param {event} e 
     */
    handleChangeFormSendPincodeEGestor(e) {
        this.setState({ phone: e.target.value });
    }

    /**
     * Handle Submit Form Send Pincode
     * @param {event} e 
     */
    async handleSubmitFormSendPincodeEGestor(e) {
        e.preventDefault();

        const { phone } = this.state;
        const validator = validatorPhoneNumber(phone);
        
        this.setState({ loading: true, displayErrorMessage: 'none', errorMessage: '' });

        if(!validator.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: validator.message });
            return;
        }

        const response = await sendPincode({ 
            msisdn: validator.phone 
        });
        
        if(!response.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response.message });
            return;
        }

        this.setState({
            loading: false,
            phone: validator.phone,
            displayFormSendPincodeEGestor: 'hidden',
            displayFormValidatePincodeEGestor: 'show',
            transaction_id: response.transaction_id
        });        
    }

    /**
     * Handle Change Form Validate Pincode
     * @param {event} e 
     */
    handleChangeFormValidatePincodeEGestor(e) {
        this.setState({ pincode: e.target.value });
    }

    /**
     * Handle Submit Form Validate Pincode
     * @param {event} e 
     */
    async handleSubmitFormValidatePincodeEGestor(e) {
        e.preventDefault();
        const { transaction_id, pincode, phone } = this.state;

        this.setState({ loading: true, displayErrorMessage: 'none', errorMessage: '' });

        const response1 = await validatePincode({ 
            transaction_id: transaction_id, 
            pincode: pincode
        });

        if(!response1.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response1.message });
            return;
        }
        
        const response2 = await authorization({ 
            'fs-auth-token': response1.fs_auth_token 
        });

        if(!response2.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response2.message });
            return;
        }

        const response3 = await verifyCustomToken({ 
            token: response2.custom_token, 
            returnSecureToken: true 
        });

        if(!response3.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response3.message });
            return;
        }

        await createToken(response3.id_token, response2.custom_token, phone);

        let responsePacks = await getPacks();

        let responseAuthorize = await authorizePartner({
            partner: "egestor",
            cnpj: responsePacks.user.cnpj,
            email: responsePacks.user.email
        })

        console.log('packs', responsePacks);
        console.log('authorize parceiro', responseAuthorize);
        let redirect = true;
        let redirectUrl = "https://oiseunegocio.com.br";
        if (responseAuthorize.status){
            redirectUrl = responseAuthorize.access_url;
            setTimeout(async () => {
                this.setState({
                    displayModal: 'hidden',
                    displaySuccessMergeAccount: 'hidden',
                    redirect: redirect,
                    redirectUrl: redirectUrl
                }); 
            }, 100);
        }else{
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: responseAuthorize.message });
        }

    }

    /**
     * Render Component
     */
    render() {
        const {
            loading,
            errorMessage,
            displayErrorMessage,
            displayFormSendPincodeEGestor,
            displayFormValidatePincodeEGestor,
            phone,
            transaction_id,
            redirect,
            pincode,
            redirectUrl
        } = this.state;        

        if (redirect) {
            window.location.href = redirectUrl;
        }

        return (
            <>
                <Loading display={loading} />
                <div>

                    <FormSendPincodeEGestor
                        phone={phone}
                        analytics={"insert_msisdn"}
                        handleChange={this.handleChangeFormSendPincodeEGestor}
                        handleSubmit={this.handleSubmitFormSendPincodeEGestor}
                        display={displayFormSendPincodeEGestor}
                    />

                    <FormValidatePincodeEGestor
                        analytics={"validate_msisdn"}
                        resendPin={"resend_pincode_msisdn"}
                        phone={phone}
                        pincode={pincode}
                        transaction_id={transaction_id}
                        handleResendPincode={this.handleResendPincode}
                        handleChange={this.handleChangeFormValidatePincodeEGestor}
                        handleSubmit={this.handleSubmitFormValidatePincodeEGestor}
                        display={displayFormValidatePincodeEGestor}
                    />
                    <ErrorMessage display={displayErrorMessage} onCloseErrorMessage={(e) => this.onCloseErrorMessage(e)} message={errorMessage} />
                </div>
            </>
        )
    }
}