import React from 'react'
import Menu from '../components/Menu'
import Footer from '../components/Footer'

export default (props) => {
    return (
        <>
            <main className="dashboard flex column">
                <Menu />
                {props.children}
            </main>
            <Footer display={props.displayFooter} />
        </>
    );
}