import React, { Component } from 'react'
import { validatorPhoneNumber } from '../../../utils/Validator/PhoneNumber'
import { validatorCNPJ } from '../../../utils/Validator/CNPJ'
import { validatorEmail } from '../../../utils/Validator/Email'
import { createToken } from '../../../utils/Token'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
// import {URL_CHAT_WHATSAPP_OI} from '../../../constants'

import {
    sendPincode,
    validatePincode,
    validateCNPJ,
    authorization,
    verifyCustomToken

} from '../../../services'

import {
    activateAccount
} from '../../../services/Products'

import {
    ModalTemplate,
    FormValidateCNPJ,
    FormSendEmail,
    FormValidatePincodeEmail,
    FormSendPincode,
    FormValidatePincode,
    ErrorMessage,
    Loading
} from '../../../components'

export default class ModalMergeAccount extends Component {

    /**
     * Contructor
     */
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            errorMessage: '',
            displayErrorMessage: 'none',
            displayModal: 'hidden',
            displayFormValidateCNPJ: 'show',
            displayFormSendEmail: 'hidden',
            displayFormValidatePincodeEmail: 'hidden',
            displayFormSendPincode: 'hidden',
            displayFormValidatePincode: 'hidden',
            displaySuccessMergeAccount: 'hidden',
            cnpj: '',
            phone: '',
            phone_transaction_id: '',
            email: '',
            email_transaction_id: '',
            pincode: '',
            cnpj_auth_token: '',
            email_auth_token: '',
            phone_auth_token: '',
            custom_token: ''
        }

        this.baseState = this.state;
        this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this);
        this.handleChangeFormValidateCNPJ = this.handleChangeFormValidateCNPJ.bind(this);
        this.handleSubmitFormValidateCNPJ = this.handleSubmitFormValidateCNPJ.bind(this);
        this.handleChangeFormSendEmail = this.handleChangeFormSendEmail.bind(this);
        this.handleSubmitFormSendEmail = this.handleSubmitFormSendEmail.bind(this);
        this.handleChangeFormValidatePincodeEmail = this.handleChangeFormValidatePincodeEmail.bind(this);
        this.handleSubmitFormValidatePincodeEmail = this.handleSubmitFormValidatePincodeEmail.bind(this);
        this.handleChangeFormSendPincode = this.handleChangeFormSendPincode.bind(this);
        this.handleSubmitFormSendPincode = this.handleSubmitFormSendPincode.bind(this);
        this.handleChangeFormValidatePincode = this.handleChangeFormValidatePincode.bind(this);
        this.handleSubmitFormValidatePincode = this.handleSubmitFormValidatePincode.bind(this);
    }

    /**
      * On Click Modal
      */
    onClickModalDisplay = (v) => {
        switch (v) {
            case true:
                this.setState({ displayModal: 'show' });
                break;

            default:
                this.setState(this.baseState);
                break;
        }
    }

    /**
     * On Close Error Message
     */
    onCloseErrorMessage = (e) => {
        this.setState({ displayErrorMessage: e, errorMessage: '' });
    }

    /**
     * Handle Change Form Validate CNPJ
     * @param {event} e 
     */
    handleChangeFormValidateCNPJ(e) {
        this.setState({ cnpj: e.target.value });
    }

    /**
     * Handle Submit Form Validate CNPJ
     * @param {event} e 
     */
    async handleSubmitFormValidateCNPJ(e) {
        e.preventDefault();

        const { cnpj } = this.state;
        const validator = validatorCNPJ(cnpj);
        
        this.setState({ loading: true, displayErrorMessage: 'none', errorMessage: '' });

        if(!validator.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: validator.message });
            return;
        }

        let body = { cnpj: validator.cnpj };
        const response = await validateCNPJ(body);
        
        if(!response.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response.message });
            return;
        }

        this.setState({
            loading: false,
            displayFormValidateCNPJ: 'hidden',
            displayFormSendEmail: 'show',
            cnpj_auth_token: response.cnpj_auth_token
        });
    }

    /**
    * Handle Change Form Validate Email
    * @param {event} e 
    */
    handleChangeFormSendEmail(e) {
        this.setState({ email: e.target.value });
    }

    /**
     * Handle Submit Form Validate Email
     * @param {event} e 
     */
    async handleSubmitFormSendEmail(e) {
        e.preventDefault();
        
        const { email } = this.state;
        const validator = validatorEmail(email);
        
        this.setState({ loading: true, displayErrorMessage: 'none', errorMessage: '' });

        if(!validator.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: validator.message });
            return;
        }

        let body = { email: validator.email };
        const response = await sendPincode(body);
        
        if(!response.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: 'Por favor, digite um email válido!' });
            return;
        }

        this.setState({
            loading: false,
            email: validator.email,
            displayFormSendEmail: 'hidden',
            displayFormValidatePincodeEmail: 'show',
            email_transaction_id: response.transaction_id
        });
    }

    /**
    * Handle Change Form Validate Pincode Email
    * @param {event} event 
    */
    handleChangeFormValidatePincodeEmail(e) {
        this.setState({ pincode: e.target.value });
    }

    /**
    * Handle Submit Form Validate Pincode and Serial
    * @param {event} event 
    */
    async handleSubmitFormValidatePincodeEmail(e) {
        e.preventDefault();

        const { pincode, email_transaction_id } = this.state;

        this.setState({ loading: true, displayErrorMessage: 'none', errorMessage: '' });

        const response = await validatePincode({ 
            transaction_id: email_transaction_id, 
            pincode: pincode 
        });

        if(!response.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response.message });
            return;
        }

        this.setState({
            loading: false,
            displayFormValidatePincodeEmail: 'hidden',
            displayFormSendPincode: 'show',
            email_auth_token: response.fs_auth_token
        });
    }

    /**
    * Handle Change Form Send Pincode
    * @param {event} e 
    */
    handleChangeFormSendPincode(e) {
        this.setState({ phone: e.target.value });
    }

    /**
     * Handle Submit Form Send Pincode
     * @param {event} e 
     */
    async handleSubmitFormSendPincode(e) {
        e.preventDefault();

        const { phone } = this.state;
        const validator = validatorPhoneNumber(phone);
        
        this.setState({ loading: true, displayErrorMessage: 'none', errorMessage: '' });

        if(!validator.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: validator.message });
            return;
        }

        const response = await sendPincode({ 
            msisdn: validator.phone 
        });
        
        if(!response.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response.message });
            return;
        }

        this.setState({
            loading: false,
            phone: validator.phone,
            displayFormSendPincode: 'hidden',
            displayFormValidatePincode: 'show',
            phone_transaction_id: response.transaction_id
        });
    }

    /**
    * Handle Change Form Validate Pincode and Serial
    * @param {event} event 
    */
    handleChangeFormValidatePincode(e) {
        this.setState({ pincode: e.target.value });
    }

    /**
    * Handle Submit Form Validate Pincode and Serial
    * @param {event} event 
    */
    async handleSubmitFormValidatePincode(e) {
        e.preventDefault();

        const { phone, cnpj, pincode, email, phone_transaction_id, cnpj_auth_token, email_auth_token } = this.state;
        this.setState({ loading: true, displayErrorMessage: 'none', errorMessage: '' });

        const response1 = await validatePincode({ 
            transaction_id: phone_transaction_id, 
            pincode: pincode
        });

        if(!response1.status){
            this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response1.message });
            return;
        }

        setTimeout(async () => {
            const response2 = await authorization({
                "fs-auth-token": cnpj_auth_token,
                "fs-merge-token": [
                    response1.fs_auth_token,
                    email_auth_token
                ]
            });
    
            if(!response2.status){
                this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response2.message });
                return;
            }
    
            const response3 = await verifyCustomToken({ 
                token: response2.custom_token, 
                returnSecureToken: true 
            });
    
            if(!response3.status){
                this.setState({ loading: false, displayErrorMessage: 'block', errorMessage: response3.message });
                return;
            }

            const response4 = await activateAccount({
                msisdn: "55" + phone,
                cnpj: cnpj.replaceAll(/[^\d]+/g, ""),
                email: email,
                hash: "220",
                partner: "OI HUB",
                expire_days: 180
            });
    
            if (!response4.status) {
                this.setState({
                    loading: false,
                    displayErrorMessage: true,
                    errorMessage: response4.message
                });
                return;
            }

            this.setState({
                loading: false,
                displayFormValidatePincode: 'hidden',
                displaySuccessMergeAccount: 'show'
            });

            createToken(response3.id_token, response2.custom_token, phone);

            setTimeout(async () => {
                this.setState({
                    loading: true,
                    displayModal: 'hidden',
                    displaySuccessMergeAccount: 'hidden',
                    redirect: true
                }); 
            }, 4000);

        }, 5000);
    }

    /**
     * Render Component
     */
    render() {
        const {
            loading,
            errorMessage,
            displayErrorMessage,
            displayModal,
            displayFormValidateCNPJ,
            displayFormSendEmail,
            displayFormValidatePincodeEmail,
            displayFormSendPincode,
            displayFormValidatePincode,
            displaySuccessMergeAccount,
            phone,
            cnpj,
            pincode,
            email,
            redirect
        } = this.state;

        if (redirect) {
            return <Redirect to='/area-logada' />;
        }

        return (
            <>
                <Loading display={loading} />
                <button className="bt-information start_register_information" onClick={() => this.onClickModalDisplay(true)} type="button"></button>
                <ModalTemplate id="modal-merge-account" display={displayModal} onClickModalDisplay={this.onClickModalDisplay}>
                    <FormValidateCNPJ
                        cnpj={cnpj}
                        handleChange={this.handleChangeFormValidateCNPJ}
                        handleSubmit={this.handleSubmitFormValidateCNPJ}
                        display={displayFormValidateCNPJ}
                    />

                    <FormSendEmail
                        email={email}
                        handleChange={this.handleChangeFormSendEmail}
                        handleSubmit={this.handleSubmitFormSendEmail}
                        display={displayFormSendEmail}
                    />

                    <FormValidatePincodeEmail
                        resendPin={"validate_resend_pincode_email"}
                        email={email}
                        pincode={pincode}
                        handleChange={this.handleChangeFormValidatePincodeEmail}
                        handleSubmit={this.handleSubmitFormValidatePincodeEmail}
                        display={displayFormValidatePincodeEmail}
                    />

                    <FormSendPincode
                        analytics={"register_msisdn"}
                        phone={phone}
                        handleChange={this.handleChangeFormSendPincode}
                        handleSubmit={this.handleSubmitFormSendPincode}
                        display={displayFormSendPincode}
                    />

                    <FormValidatePincode
                        analytics={"validate_register_msisdn"}
                        resendPin={"validate_resend_pincode_msisdn"}
                        phone={phone}
                        pincode={pincode}
                        handleChange={this.handleChangeFormValidatePincode}
                        handleSubmit={this.handleSubmitFormValidatePincode}
                        display={displayFormValidatePincode}
                    />

                    <section className={`box-modal flex column congrats aic jcc ${displaySuccessMergeAccount}`} style={{ width: '100%' }}>
                        <p><strong>Parabéns!</strong></p>
                        <p>Seu <strong>CNPJ</strong> foi vínculado ao e-mail <strong>{email}</strong>, e ao número de celular <strong>{phone}</strong>.</p>
                        <p>Qualquer problema ou dúvida, acesse a nossa central de <Link to="/ajuda">ajuda</Link>.</p>
                        <p>Você está sendo redirecionado, por favor aguarde...</p>
                    </section>

                    <ErrorMessage display={displayErrorMessage} onCloseErrorMessage={(e) => this.onCloseErrorMessage(e)} message={errorMessage} />
                </ModalTemplate>
            </>
        )
    }
}