import IP from 'ip'
import Bowser from 'bowser'
import JWT from 'jsonwebtoken'

import {
    SECRET_KEY
} from '../../constants'

/**
 * Function Authorize Token
 */
export const isAuthorizated = () => {
    const ip = IP.address();
    const token = localStorage.getItem('token');
    const browserParse = Bowser.getParser(window.navigator.userAgent);
    const browser = browserParse.getBrowserName();

    return JWT.verify(token, SECRET_KEY, function (err, decoded) {
        if (err) {
            localStorage.removeItem('token');
            return false;
        }

        if(!(browser === decoded.data.browser) && (ip === decoded.data.ip)){
            localStorage.removeItem('token');        
            return false;
        }

        return true;
    });
}

/**
 * Function Create Token
 * @param {obj} idToken 
 * @param {json} custom_token 
 * @param {int} phone 
 */
export async function createToken(idToken, custom_token, phone) {

    const browserParse = Bowser.getParser(window.navigator.userAgent);
    const browser = browserParse.getBrowserName();
    const ip = IP.address();
    return new Promise((resolve, reject) => {
        JWT.sign({
            data: {
                token: idToken,
                custom_token: custom_token,
                phone: '+55' + phone,
                browser: browser,
                ip: ip
            }
        }, SECRET_KEY, { expiresIn: '2h' }, async (err, token) => {
            if (err) {
                reject(err);
                return false;
            }
            localStorage.setItem('token', token);
            resolve(token);
        });
    });
}

/**
 * Function Validate Token
 * @param {string} type 
 */
export function validateToken(type) {
    const token = localStorage.getItem('token');

    return JWT.verify(token, SECRET_KEY, function (err, decoded) {
        if (err) {
            console.log(err);
            return false;
        }

        switch (type) {
            case 'token':
                return decoded.data.token;
            case 'phone':
                return decoded.data.phone;
            case 'custom_token':
                return decoded.data.custom_token;
            default:
                return decoded.data.token;
        }
    });
}

/**
 * Function Remove Token
 */
export function removeToken() {
    const token = localStorage.getItem('token');

    return JWT.verify(token, SECRET_KEY, function (err) {
        if (err) {
            return false;
        }
        localStorage.removeItem('token');
        localStorage.removeItem('custom_token');
        return window.location = '/';
    });
}