import React from "react";

import SGOISEGURANCA1 from "./images/1.jpg";
import SGOISEGURANCA2 from "./images/2.jpg";
import SGOISEGURANCA3 from "./images/3.jpg";
import SGOISEGURANCA5 from "./images/5.jpg";

export default () => {
  return (
    <div>
      {/* SECUNDARY GALLERY */}
      <div className="secundary-gallery">
        <div className="content-center">
          <ul className="list-secundary-gallery owl-carousel owl-theme">
            <li>
              <div className="sg-header">
                <p className="_title">Proteção Completa</p>

                <p>
                  O cliente vai navegar protegido de vírus e invasões virtuais,
                  além de realizar compras online e transações bancárias sem se
                  preocupar com a sua privacidade.
                </p>
              </div>

              <div className="_img">
                <img src={SGOISEGURANCA1} alt="Imagem Galeria Secundária" />
              </div>
            </li>

            <li>
              <div className="sg-header">
                <p className="_title">Mais Espaço </p>

                <p>
                  O jeito mais prático de guardar os arquivos mais importantes,
                  assim como fotos, vídeos e contatos de forma segura e
                  acessível em um só lugar. Protege seus conteúdos e sincroniza
                  os arquivos para o cliente acessar sempre que quiser.
                </p>
              </div>

              <div className="_img">
                <img src={SGOISEGURANCA2} alt="Imagem Galeria Secundária" />
              </div>
            </li>

            <li>
              <div className="sg-header">
                <p className="_title">Wi-Fi Seguro</p>

                <p>
                  Utilize qualquer Wi-Fi com privacidade e sem preocupação. A
                  melhor maneira de se conectar à internet com tranquilidade e
                  segurança através de redes WiFi públicas.
                </p>
              </div>

              <div className="_img">
                <img src={SGOISEGURANCA3} alt="Imagem Galeria Secundária" />
              </div>
            </li>

            <li>
              <div className="sg-header">
                <p className="_title">Otimizador</p>

                <p>
                  Obtenha a máxima performance do seu smartphone, deixando-o
                  mais leve, rápido e com a duração da bateria muito maior. Em
                  poucos passos, é possível limpar a memória do smartphone,
                  liberar espaço, acelerar jogos, resfriar a bateria e com o uso
                  de senha, proteger o acesso aos seus apps mais importantes.
                </p>
              </div>

              <div className="_img">
                <img src={SGOISEGURANCA5} alt="Imagem Galeria Secundária" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
