import React, { Component } from 'react'
import { sendPincode } from '../../../services/Authentication'

export default class ButtonResendPincode extends Component {

    constructor(){
        super();
        this.state = {
            btnText: 'Reenviar código',
            btnResendPincode: 'block',
            messageResendPincode: 'none',
            count: 10
        }

        this.timer = this.timer.bind(this);
    }
    
    /**
     * Timer Count
     */
    timer() {
        let newCount = this.state.count - 1;
        if(newCount >= 1) { 
            this.setState({ count: newCount });
        } else {
            clearInterval(this.state.intervalId);
            this.setState({ btnResendPincode: 'block', messageResendPincode: 'none', count: 10 });
        }
    }

    /**
    * Handle Resend Pincode
    * @param {event} event 
    */
    async handleResendPincode(type, value) {
        let body = (type === 'phone')? {msisdn: value} : {email: value}; 

        //let count = 10;
        console.log('[send-resend-pincode]', body);

        var intervalId = setInterval(this.timer, 1000);
        this.setState({ btnResendPincode: 'none', messageResendPincode: 'block', intervalId: intervalId});

        try {
            let response = await sendPincode(body);
            console.log('[success-resend-pincode]', response.data);
        } catch (error) {
            if (error.response) {
                console.log('[error-business]: ', error.response.data);
            } else if (error.request) {
                console.log('[error-request]:', error.request)
            } else {
                console.log('[error-api]:', error.message);
            }            
        }
    }

    /**
    * Render Component
    */
    render() {        
        const { btnText, btnResendPincode, messageResendPincode } = this.state;

        return (
            <div className="resend-pincode">
                <p style={{ textDecoration: 'underline', cursor: 'pointer', display: `${btnResendPincode}`}} className={`btn-resend-pincode ${this.props.resendPin}`} onClick={() => this.handleResendPincode(this.props.type, this.props.value)}>{btnText}</p>
                <p style={{ display: `${messageResendPincode}`}}>Reenviando em {this.state.count} segundos...</p>
            </div>
        );
    }
}