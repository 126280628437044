import React, { Component } from "react";
import { Redirect } from "react-router";
import { validatorPhoneNumber } from "../../../utils/Validator/PhoneNumber";
import { createToken } from "../../../utils/Token";
import { URL_PORTAL_CLOUD } from "../../../constants";
import {
  sendPincode,
  validatePincode,
  authorization,
  verifyCustomToken,
} from "../../../services";

import {
  ModalTemplate,
  FormSendPincode,
  FormValidatePincode,
  ErrorMessage,
  Loading,
} from "../../../components";

export default class ModalLogin extends Component {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.state = {
      loading: false,
      errorMessage: "",
      displayErrorMessage: "none",
      displayModal: "hidden",
      displayFormSendPincode: "show",
      displayFormValidatePincode: "hidden",
      phone: "",
      transaction_id: "",
      pincode: "",
      fs_custom_token: "",
      custom_token: "",
      redirect: false,
    };

    this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this);
    this.handleChangeFormSendPincode =
      this.handleChangeFormSendPincode.bind(this);
    this.handleSubmitFormSendPincode =
      this.handleSubmitFormSendPincode.bind(this);
    this.handleChangeFormValidatePincode =
      this.handleChangeFormValidatePincode.bind(this);
    this.handleSubmitFormValidatePincode =
      this.handleSubmitFormValidatePincode.bind(this);
  }

  onClickButton = (e) => {
    e.preventDefault();
    window.location.href = URL_PORTAL_CLOUD;
  };

  /**
   * On Click Modal Display
   */
  onClickModalDisplay = (v) => {
    switch (v) {
      case true:
        this.setState({
          displayModal: "show",
        });
        break;

      default:
        let err = document.getElementsByClassName("div-error");

        for (var i = 0; i < err.length; i++) {
          err[i].style.display = "none";
        }

        this.setState({
          displayModal: "hidden",
          displayFormSendPincode: "show",
          displayFormValidatePincode: "hidden",
        });
        break;
    }
  };

  /**
   * On Close Error Message
   */
  onCloseErrorMessage = (e) => {
    this.setState({ displayErrorMessage: e, errorMessage: "" });
  };

  /**
   * Handle Change Form Send Pincode
   * @param {event} e
   */
  handleChangeFormSendPincode(e) {
    this.setState({ phone: e.target.value });
  }

  /**
   * Handle Submit Form Send Pincode
   * @param {event} e
   */
  async handleSubmitFormSendPincode(e) {
    e.preventDefault();

    const { phone } = this.state;
    const validator = validatorPhoneNumber(phone);

    this.setState({
      loading: true,
      displayErrorMessage: "none",
      errorMessage: "",
    });

    if (!validator.status) {
      this.setState({
        loading: false,
        displayErrorMessage: "block",
        errorMessage: validator.message,
      });
      return;
    }

    const response = await sendPincode({
      msisdn: validator.phone,
    });

    if (!response.status) {
      this.setState({
        loading: false,
        displayErrorMessage: "block",
        errorMessage: response.message,
      });
      return;
    }

    this.setState({
      loading: false,
      phone: validator.phone,
      displayFormSendPincode: "hidden",
      displayFormValidatePincode: "show",
      transaction_id: response.transaction_id,
    });
  }

  /**
   * Handle Change Form Validate Pincode
   * @param {event} e
   */
  handleChangeFormValidatePincode(e) {
    this.setState({ pincode: e.target.value });
  }

  /**
   * Handle Submit Form Validate Pincode
   * @param {event} e
   */
  async handleSubmitFormValidatePincode(e) {
    e.preventDefault();
    const { transaction_id, pincode, phone } = this.state;

    this.setState({
      loading: true,
      displayErrorMessage: "none",
      errorMessage: "",
    });

    const response1 = await validatePincode({
      transaction_id: transaction_id,
      pincode: pincode,
    });

    if (!response1.status) {
      this.setState({
        loading: false,
        displayErrorMessage: "block",
        errorMessage: response1.message,
      });
      return;
    }

    const response2 = await authorization({
      "fs-auth-token": response1.fs_auth_token,
    });

    if (!response2.status) {
      this.setState({
        loading: false,
        displayErrorMessage: "block",
        errorMessage: response2.message,
      });
      return;
    }

    const response3 = await verifyCustomToken({
      token: response2.custom_token,
      returnSecureToken: true,
    });

    if (!response3.status) {
      this.setState({
        loading: false,
        displayErrorMessage: "block",
        errorMessage: response3.message,
      });
      return;
    }

    createToken(response3.id_token, response2.custom_token, phone);

    setTimeout(async () => {
      this.setState({
        displayModal: "hidden",
        displaySuccessMergeAccount: "hidden",
        redirect: true,
      });
    }, 2000);
  }

  /**
   * Render Component
   */
  render() {
    const {
      loading,
      errorMessage,
      displayErrorMessage,
      displayModal,
      displayFormSendPincode,
      displayFormValidatePincode,
      phone,
      transaction_id,
      redirect,
      pincode,
    } = this.state;

    if (redirect) {
      return <Redirect to="/area-logada" />;
    }

    return (
      <>
        <Loading display={loading} />
        <button
          className="button-login btn-login start_login btn_login_acesse_agora"
          onClick={this.onClickButton}
          type="button"
        ></button>
        <ModalTemplate
          id="modal-login"
          display={displayModal}
          onClickModalDisplay={this.onClickModalDisplay}
        >
          <FormSendPincode
            phone={phone}
            analytics={"insert_msisdn"}
            handleChange={this.handleChangeFormSendPincode}
            handleSubmit={this.handleSubmitFormSendPincode}
            display={displayFormSendPincode}
          />

          <FormValidatePincode
            analytics={"validate_msisdn"}
            resendPin={"resend_pincode_msisdn"}
            phone={phone}
            pincode={pincode}
            transaction_id={transaction_id}
            handleResendPincode={this.handleResendPincode}
            handleChange={this.handleChangeFormValidatePincode}
            handleSubmit={this.handleSubmitFormValidatePincode}
            display={displayFormValidatePincode}
          />
          <ErrorMessage
            display={displayErrorMessage}
            onCloseErrorMessage={(e) => this.onCloseErrorMessage(e)}
            message={errorMessage}
          />
        </ModalTemplate>
      </>
    );
  }
}
