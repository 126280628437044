import React from "react";
import { URL_CHAT_WHATSAPP_OI } from "../../constants";
import ModalLogin from "../../components/Modal/Login";
import ModalMergeAccount from "../../components/Modal/MergeAccount";
import FooterHome from "../../components/Footer/Home";

import FaqOList from "../Home/HomeFaq/OList";
import FaqEGestor from "../Home/HomeFaq/EGestor";
import FaqOiSeguranca from "../Home/HomeFaq/OiSeguranca";
import FaqOiSeuNegocio from "../Home/HomeFaq/OiSeuNegocio";

import BoxLoginEGestor from "../../components/Products/Home/EGestor";
import BoxLoginOiSeguranca from "../../components/Products/Home/OiSeguranca";

import BannerHomeTop from "../../components/Banner/HomeTop";
import BannerOList from "../../components/Banner/OList";
import BannerEGestor from "../../components/Banner/EGestor";
import BannerOiSeguranca from "../../components/Banner/OiSeguranca";

import LOGOOIHUB from "./images/logo-oi-hub.svg";
import LOGOOEGESTOR from "./images/logo-egestor-colorido.png";
import LOGOOOISEGURANCA from "./images/logo-oi-seguranca-colorido.png";

export default () => {
  return (
    <div>
      <div className="content-menu-mobile">
        <div className="mask"></div>
        <div className="menu-mobile">
          <div>
            <div className="login-section">
              <div className="_conte">
                <p>
                  <small>Já está cadastrado? Efetue o Login:</small>
                </p>
                <ModalLogin />
                <ModalMergeAccount />
              </div>
              <div className="js-close close-menu-mobile">
                <i className="fas fa-times"></i>
              </div>
            </div>
            <div className="content-list-items">
              <div className="back-page-product state-home">
                <a href="/">
                  <i className="fas fa-chevron-left"></i>
                  <p>Home</p>
                </a>
              </div>
              <ul className="list-items">
                <li>
                  <p>
                    <a
                      href="/"
                      className="js-link js-close color-transition nossos_servicos-oi_seguranca"
                      data-link="oiseguranca"
                    >
                      Oi Segurança
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <a
                      href="/"
                      className="js-link js-close color-transition nossos_servicos-egestor"
                      data-link="egestor"
                    >
                      Gestão Empresarial
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <main className="oi_home">
        <header className="_header">
          <div className="content-center">
            <div className="logo">
              <a href="/">
                <img src={LOGOOIHUB} alt="Logo Oi Hub" />
              </a>
            </div>
            <div className="product">
              <button className="show-product nossos_servicos">
                Nossos Serviços
              </button>

              <a href="#category" className="quero_ser_cliente_oi">
                Quero ser Cliente Oi
              </a>

              <a href="#_footer" className="preciso_de_ajuda">
                Preciso de Ajuda
              </a>

              <a
                href="https://www.oicontasb2b.com.br/#/oi/login"
                target="_blank"
                rel="noopener noreferrer"
                className="segunda_via_conta"
              >
                2ª via de Conta
              </a>
            </div>
            <div className="login-section">
              <p>
                <small>Já está cadastrado? Efetue o Login:</small>
              </p>
              <ModalLogin />
              <ModalMergeAccount />
            </div>
            <div className="menu-hamburguer">
              <button className="menu-mobile">
                <i className="fas fa-bars"></i>
              </button>
            </div>
          </div>
          <div className="items-menu height-transition">
            <div className="content-center">
              <ul className="list-items">
                <li>
                  <p>
                    <a
                      href="/"
                      className="js-link color-transition nossos_servicos-oi_seguranca"
                      data-link="oiseguranca"
                    >
                      Oi Segurança
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <a
                      href="/"
                      className="js-link color-transition nossos_servicos-egestor"
                      data-link="egestor"
                    >
                      Gestão Empresarial
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <BannerHomeTop />
        <div className="secundary-buttons">
          <div className="content-center">
            <ModalLogin />
            <a
              href={URL_CHAT_WHATSAPP_OI}
              target="_blank"
              rel="noopener noreferrer"
              className="second-button btn_nao_cliente_assine_agora_A"
            >
              Não é cliente? Assine agora
            </a>
          </div>
        </div>
        <section className="_content">
          <aside className="_category state-mobile-content" id="category">
            <div className="content-center">
              <section className="box">
                <div
                  className="emcompass-product info-plan"
                  style={{ padding: "0", borderTop: "0" }}
                  data-emcompass="egestor"
                >
                  <section className="_box">
                    <header className="_box-header">
                      <h1>Tenha uma visão completa da sua empresa</h1>

                      <p>
                        Com o E-gestor você automatiza seus resultados e diminui
                        os custos. Tenha o controle financeiro, visão das suas
                        vendas, controle de estoque, cadastro de clientes e
                        relatórios em uma única plataforma.
                      </p>
                    </header>

                    <div className="login-plan state-home">
                      <BoxLoginEGestor />
                    </div>
                  </section>
                </div>
                <div
                  className="emcompass-product info-plan"
                  style={{ padding: "0", borderTop: "0" }}
                  data-emcompass="oiseguranca"
                >
                  <section className="_box">
                    <header className="_box-header">
                      <h1>Tranquilidade para sua vida digital</h1>
                      <p>
                        O Oi Segurança é bem simples e intuitivo. Basta fazer o
                        download do aplicativo, fazer o seu login e decidir como
                        usará suas funcionalidades.
                      </p>
                    </header>
                    <div className="login-plan state-home">
                      <BoxLoginOiSeguranca />
                    </div>
                  </section>
                </div>
              </section>

              <section className="box-two">
                <p className="_title">
                  Soluções que aceleram as vendas do seu negócio
                </p>
                <p className="_title-two">
                  Selecione o produto desejado para navegar:
                </p>

                <ul className="_list-product">
                  <li
                    className="js-link produto_desejado-oi_seguranca"
                    data-link="oiseguranca"
                  >
                    <img src={LOGOOOISEGURANCA} alt="Logo OList" />
                  </li>
                  <li
                    className="js-link produto_desejado-egestor"
                    data-link="egestor"
                  >
                    <img src={LOGOOEGESTOR} alt="Logo OList" />
                  </li>
                </ul>
                <a
                  href={URL_CHAT_WHATSAPP_OI}
                  style={{
                    textDecoration: "none",
                    display: "inline-block",
                    marginTop: "50px",
                  }}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="bt-information quero_ser_cliente_oi_seu_negocio"
                >
                  Quero ser cliente Oi Seu Negócio
                </a>
              </section>
            </div>
          </aside>

          <div className="emcompass-product" data-emcompass="olist">
            <div className="info-plan">
              <div className="content-center">
                <div className="_box-movie">
                  <iframe
                    src="https://www.youtube.com/embed/zkFO-QvfY10"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>

                  <div className="know-more">
                    <a href="/m-olist#box-plan">Saiba mais</a>
                    <ModalMergeAccount />
                  </div>
                </div>
              </div>
            </div>
            <BannerOList />
            <div className="content-faq state-home">
              <FaqOList />
            </div>
          </div>
          <div className="emcompass-product" data-emcompass="egestor">
            <div className="info-plan">
              <div className="content-center">
                <div className="_box-movie">
                  <iframe
                    src="https://www.youtube.com/embed/eGk1gUCGhro"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>

                  <div className="know-more">
                    <a href="/m-egestor#box-plan">Saiba mais</a>
                    <ModalMergeAccount />
                  </div>
                </div>
              </div>
            </div>
            <BannerEGestor />
            <div className="content-faq state-home">
              <FaqEGestor />
            </div>
          </div>
          <div className="emcompass-product" data-emcompass="oiseguranca">
            <div className="info-plan">
              <div className="content-center">
                <div className="_box-movie">
                  <div className="know-more">
                    <a href="/m-oiseguranca#box-plan">Saiba mais</a>
                    <ModalMergeAccount />
                  </div>
                </div>
              </div>
            </div>
            <BannerOiSeguranca />
            <div className="content-faq state-home">
              <FaqOiSeguranca />
            </div>
          </div>
        </section>
        <section className="faq-oi-seu-negocio content-faq">
          <div className="content-center">
            <header>
              <h2>
                Perguntas Frequentes sobre <strong>Oi Seu Negócio</strong>
              </h2>
            </header>
            <FaqOiSeuNegocio />
          </div>
        </section>
        <FooterHome />
      </main>
    </div>
  );
};
