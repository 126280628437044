import React, { Component } from "react";
import Layout from "../../layout";
import { getPacks, authorizePartner } from "../../services";

import {
  ProductOiAds,
  ProductEGestor,
  ProductSegurancaLight,
  ErrorProducts,
  Loading,
} from "../../components";

export default class Dashboard extends Component {
  /**
   * Contructor
   */
  constructor() {
    super();
    this.state = {
      error: false,
      loading: true,
      idToken: "",
      user_id: "",
      serial: "",
      cnpj: "",
      email: "",
      partner: "",
      products: [],
    };

    this.onClickAuthorizePartner = this.onClickAuthorizePartner.bind(this);
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    document.querySelector("chatbot").style.display = "block";
    this.getPacks();
  }

  /**
   * Component Will Mount
   */
  componentWillUnmount() {
    document.querySelector("chatbot").style.display = "none";
  }

  /**
   *  Get Packs
   */
  async getPacks() {
    let response = await getPacks();

    if (!response.status) {
      this.setState({
        loading: false,
        error: true,
      });
      return;
    }

    this.setState({
      loading: false,
      products: response.products,
      serial: response.user.msisdn,
      cnpj: response.user.cnpj,
      email: response.user.email,
      user_id: response.user.id,
      idToken: response.token,
    });
  }

  /**
   * Function On Click Authorize Partner
   * @param {event} e
   * @param {string} partner
   * @param {int} partnerId
   * @param {int} serviceId
   * @param {int} subscriptionId
   */
  async onClickAuthorizePartner(
    e,
    partner,
    partnerId,
    serviceId,
    subscriptionId
  ) {
    e.preventDefault();

    const { cnpj, email } = this.state;

    this.setState({
      loading: true,
      errorMessage: false,
    });

    const response = await authorizePartner({
      cnpj: cnpj,
      email: email,
      partner: partner,
      partnerId: partnerId,
      serviceId: serviceId,
      subscriptionId: subscriptionId,
    });

    if (!response.status) {
      this.setState({
        loading: false,
        error: true,
      });
      return;
    }

    setTimeout(() => {
      this.setState({ loading: false });
      window.open(response.access_url, "_blank");
    }, 1000);
  }

  /**
   * Get Products User
   * @param {array} rows
   */
  getProducts(rows) {
    const { user_id, idToken } = this.state;

    if (rows.pom) {
      return <ProductSegurancaLight user_id={user_id} rows={rows.pom} />;
    }

    if (rows.egestor) {
      return (
        <ProductEGestor
          rows={rows}
          onClickAuthorizePartner={this.onClickAuthorizePartner}
        />
      );
    }

    if (rows.oiads) {
      return (
        <ProductOiAds
          onClickAuthorizePartner={this.onClickAuthorizePartner}
          idToken={idToken}
          rows={rows.oiads}
        />
      );
    }
  }

  /**
   * Render Component
   */
  render() {
    const { error, loading, products } = this.state;

    if (error) {
      return (
        <Layout displayFooter="hidden">
          <ErrorProducts />
        </Layout>
      );
    } else if (loading) {
      return <Loading display={loading} />;
    } else {
      return (
        <Layout displayFooter="hidden">
          <div className="center screen_view">
            <section className="grid-box flex column">
              <header className="flex column">
                <h1 style={{ fontSize: "2.4rem" }}>Meus Planos</h1>
                <h1 style={{ marginTop: "3rem" }}>
                  Abaixo estão listados todos os seus planos ativos:
                </h1>
              </header>
              <section
                className="list-box"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
                  gap: "25px",
                }}
              >
                {products.map((rows, key) => (
                  <React.Fragment key={key}>
                    {this.getProducts(rows)}
                  </React.Fragment>
                ))}
              </section>
            </section>
          </div>
        </Layout>
      );
    }
  }
}
