import axios from "axios";
import uuidv4 from "uuid/v4";
import { URL_BACKEND, REQUESTER_NAME, BRAND_NAME } from "../../constants";

import { validateToken } from "../../utils/Token";

/**
 * Function Get Download Link Security
 * @param {json} body
 */
export async function getDownloadLinkSecurity(body) {
  try {
    console.log("[get-download-link-security]:", body);
    const response = await axios.post(
      `${URL_BACKEND}/bff/api/v2/security/download-link`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "X-FS-Correlation-ID": uuidv4(),
          "X-FS-Requester-Name": REQUESTER_NAME,
          "X-FS-Brand-Name": BRAND_NAME,
          Authorization: `Bearer ${validateToken("token")}`,
        },
      }
    );
    console.log("[success-get-download-link-security]:", response.data);

    return { status: true, downloadLink: response.data.data.DownloadLink };
  } catch (error) {
    console.log("[error-api]:", error.request);
    return {
      status: false,
      message: `Ops, ocorreu um erro!`,
    };
  }
}
