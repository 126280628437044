import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

/**
 * Pages
 */
import Home from "./pages/Home";
import HomeMobileEGestor from "./pages/Home/HomeMobile/EGestor";
import HomeMobileOiSeguranca from "./pages/Home/HomeMobile/OiSeguranca";
import Dashboard from "./pages/Dashboard";
import AjudaOiSeguranca from "./pages/Ajuda/OiSeguranca";
import AjudaEGestor from "./pages/Ajuda/EGestor";
import AjudaOiAds from "./pages/Ajuda/OiAds";
import AjudaPostgrain from "./pages/Ajuda/Postgrain";

/**
 * Authorize
 */
import { isAuthorizated } from "./utils/Token";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthorizated() === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

/**
 * Routes
 */
export default () => (
  <BrowserRouter basename="/">
    <Switch>
      <Route path="/" exact={true} component={Home} />
      <Route path="/m-egestor" exact={true} component={HomeMobileEGestor} />
      <Route
        path="/m-oiseguranca"
        exact={true}
        component={HomeMobileOiSeguranca}
      />
      <Redirect from="/chat" to="/?show-bot" />
      <PrivateRoute
        path="/ajuda-oi-seguranca"
        exact={true}
        component={AjudaOiSeguranca}
      />
      <PrivateRoute
        path="/ajuda-egestor"
        exact={true}
        component={AjudaEGestor}
      />
      <PrivateRoute path="/ajuda-oi-ads" exact={true} component={AjudaOiAds} />
      <PrivateRoute
        path="/ajuda-oi-postgrain"
        exact={true}
        component={AjudaPostgrain}
      />
      <PrivateRoute path="/area-logada" exact={true} component={Dashboard} />
      <Route path="*" component={() => <Redirect to="/" />} />
    </Switch>
  </BrowserRouter>
);
