import React from 'react'

import SGOLIST1 from './images/olist-carrossel-secundario-desktop-01.jpg'
import SGOLIST2 from './images/olist-carrossel-secundario-desktop-02.jpg'
import SGOLIST3 from './images/olist-carrossel-secundario-desktop-03.jpg'
import SGOLIST4 from './images/olist-carrossel-secundario-desktop-04.jpg'
import SGOLIST5 from './images/olist-carrossel-secundario-desktop-05.jpg'

export default () => {
    return (
        <div>
            {/* SECUNDARY GALLERY */}
            <div className="secundary-gallery">
                <div className="content-center">
                    <ul className="list-secundary-gallery owl-carousel owl-theme">
                        <li>
                            <div className="sg-header">
                                <p className="_title">Seu Produto nos principais Marketplaces do Brasil</p>

                                <p>Venda nos maiores marketplaces do Brasil: Shoptime, Extra, Pontofrio, Americanas, Mercado Livre, Via Varejo, Carrefour, Amazon, Casas Bahia, Submarino e muito mais! </p>
                            </div>

                            <div className="_img">
                                <img src={SGOLIST1} alt="Imagem Galeria Secundária" />
                            </div>
                        </li>

                        <li>
                            <div className="sg-header">
                                <p className="_title">Otimização de anúncio</p>

                                <p>Conte com orientações de especialistas para revisar o cadastro dos seus produtos e sugerir melhorias para aumentar a performance do negócio.</p>
                            </div>

                            <div className="_img">
                                <img src={SGOLIST2} alt="Imagem Galeria Secundária" />
                            </div>
                        </li>

                        <li>
                            <div className="sg-header">
                                <p className="_title">Ferramenta de comparação de preços</p>

                                <p>Ferramenta de competitividade que analisa o preço dos produtos e diz se está de acordo com a média de mercado.</p>
                            </div>

                            <div className="_img">
                                <img src={SGOLIST3} alt="Imagem Galeria Secundária" />
                            </div>
                        </li>

                        <li>
                            <div className="sg-header">
                                <p className="_title">Integração com parceiros</p>

                                <p>Automatização da geração de nota fiscal e faturamento dos pedidos com os integradores e ERPs.</p>
                            </div>

                            <div className="_img">
                                <img src={SGOLIST4} alt="Imagem Galeria Secundária" />
                            </div>
                        </li>

                        <li>
                            <div className="sg-header">
                                <p className="_title">Novo pedido</p>

                                <p>Notificação em tempo real da entrada de um novo pedido por meio do aplicativo Olist.</p>
                            </div>

                            <div className="_img">
                                <img src={SGOLIST5} alt="Imagem Galeria Secundária" />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}