import React from 'react'
import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types';

const FormSendPincodeEGestor = ({ display, handleSubmit, phone, handleChange, analytics}) => {
    return (
        <div className={display}>
            <header className="flex column header-form">
                <h1 className="title-login-plan"><strong>Você já é cliente E-gestor? Logue-se abaixo:</strong></h1>
                <h2 style={{ fontSize: '16px', marginTop: '20px' }}>Entre com o seu telefone para ter acesso ao sistema:</h2>
            </header>
            <form className="frm-asso flex column" onSubmit={handleSubmit}>    
                <NumberFormat
                    value={phone}
                    onChange={handleChange}
                    type="phone"
                    name="phone"
                    placeholder="Digite o número do seu celular com o DDD"
                    required
                    format="(##) #####-####" mask="_"
                    className="product-login"
                />
                <div style={{ display: 'flex', marginTop: '20px'}}>
                    <label htmlFor="toggle" style={{ position: 'relative', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                        <input type="checkbox" id="toggle" name="checked" required style={{ width: '41px', height: '26px' }} />
                        <span className='toogl'></span>
                    </label>

                    <small style={{marginLeft: '20px'}}>Ao acessar o Oi Seu Negócio, você está ciente que alguns dados de sua empresa serão compartilhados com os nossos parceiros de negócio. As Informações compartilhadas são: razão social, CNPJ, número do telefone, e-mail, cidade e estado. Isso garante o acesso correto aos serviços disponíveis.</small>
                </div>
                <button className={`btn-login ${analytics}`}>Continuar</button>
            </form>
        </div>
    );
};

FormSendPincodeEGestor.defaultProps = {
    analytics: ''
}

FormSendPincodeEGestor.propTypes = {
    display: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    analytics: PropTypes.string
  };
  
  export default FormSendPincodeEGestor;